import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import styled from "styled-components";
import { RotaButtonNew, RotaStatsLoader } from "@teamrota/rota-design";

import Header, { HeaderLoader } from "./header";
import { Summary, SummaryLoader } from "./summary";
import { Sidebar } from "./sidebar";
import { Table, TableLoader } from "./table";
import { Stats } from "./stats";
import TimesheetQuickAddModal from "./TimesheetQuickAddModal";

import { TimesheetStatus, Filters, FilterType } from "./types";

import useAuth from "~/src/auth/hooks/use-auth/index.js";
import { useTimesheetMembersWithStats } from "./use-timesheet-members-with-stats";

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: calc(100% - 75px); //75px header height
`;

const HeaderWrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 75px;
  right: 0;
  background-color: ${({ theme }) => theme.white};
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding: 16px 32px;
`;

const StatsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 32px;
  row-gap: 16px;
  flex-wrap: wrap;
`;

const ContentWrapper = styled.div`
  margin-top: 84px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: start;
  overflow: auto;
`;

const TimesheetsLoader = () => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <RotaStatsLoader />
        <HeaderLoader />
      </HeaderWrapper>
      <ContentWrapper>
        <SummaryLoader />
        <TableLoader />
      </ContentWrapper>
    </Wrapper>
  );
};

const Timesheets = () => {
  const auth = useAuth();
  const currentUserId = auth.info.id;

  const initialStartDate = moment(new Date())
    .subtract("14", "days")
    .toDate();

  const initialEndDate = new Date();

  const [dateRange, setDateRange] = useState<[Date, Date]>([
    initialStartDate,
    initialEndDate
  ]);

  const [search, setSearch] = useState<string>("");

  const [showSummary, setShowSummary] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const savedFilters: Filters = JSON.parse(
    localStorage.getItem(`tsFilters-${currentUserId}`) || "{}"
  );
  const savedStatuses: TimesheetStatus[] = JSON.parse(
    localStorage.getItem(`tsStatuses-${currentUserId}`) || "[]"
  );

  const [selectedStatuses, setSelectedStatuses] = useState<TimesheetStatus[]>(
    savedStatuses
  );
  const [filters, setFilters] = useState<Filters>({
    selectedVenueIds: savedFilters.selectedVenueIds || [],
    selectedRoleIds: savedFilters.selectedRoleIds || []
  });
  const [isShowQuickAddModal, setIsShowQuickAddModal] = useState(false);

  const {
    timesheetStats,
    timesheetStatusCount,
    totalBookings,
    hasMore,
    loadMore,
    currencyCode,
    timesheets,
    previewTimesheets,
    loading,
    refetch
  } = useTimesheetMembersWithStats({
    startDate: dateRange[0],
    endDate: dateRange[1],
    venueIds: filters.selectedVenueIds,
    roleIds: filters.selectedRoleIds,
    search,
    statuses: selectedStatuses
  });

  const toggleFilters = (filter: FilterType, id: string) => {
    setFilters(prevData => {
      const newFilters = { ...prevData };
      if (newFilters[filter].includes(id)) {
        newFilters[filter] = newFilters[filter].filter(f => f !== id);
      } else {
        newFilters[filter] = [...newFilters[filter], id];
      }
      return newFilters;
    });
  };

  // Save filters and statuses to local storage
  useEffect(() => {
    localStorage.setItem(`tsFilters-${currentUserId}`, JSON.stringify(filters));
  }, [filters]);
  useEffect(() => {
    localStorage.setItem(
      `tsStatuses-${currentUserId}`,
      JSON.stringify(selectedStatuses)
    );
  }, [selectedStatuses]);

  if (loading && !timesheets) return <TimesheetsLoader />;

  return (
    <Wrapper>
      <HeaderWrapper>
        <StatsWrapper>
          <StatsContainer>
            <Stats stats={timesheetStats} />
            <Header
              dateRange={dateRange}
              setRange={(range: [Date, Date]) => setDateRange(range)}
              onSearch={(value: string) => setSearch(value)}
            />
          </StatsContainer>
          <RotaButtonNew onClick={() => setIsShowQuickAddModal(true)}>
            Quick Add
          </RotaButtonNew>
        </StatsWrapper>
      </HeaderWrapper>
      <ContentWrapper>
        {showSummary && (
          <Summary
            toDoCount={timesheetStatusCount?.todoCount ?? "-"}
            inProgressCount={timesheetStatusCount?.inProgressCount ?? "-"}
            approvedCount={timesheetStatusCount?.approvedCount ?? "-"}
            lockedCount={timesheetStatusCount?.lockedCount ?? "-"}
          />
        )}
        <Table
          timesheets={[...previewTimesheets, ...(timesheets || [])]}
          hasMore={hasMore}
          loadMore={loadMore}
          currencyCode={currencyCode}
          totalCount={totalBookings}
          showSummary={showSummary}
          onToggleExpand={() => setShowSummary(!showSummary)}
          onOpenSidebar={() => setIsSidebarOpen(true)}
          refetch={() => refetch()}
        />
        <Sidebar
          isOpen={isSidebarOpen}
          onClose={() => setIsSidebarOpen(false)}
          selectedStatuses={selectedStatuses}
          toggleSelectedStatus={(status: TimesheetStatus) => {
            if (selectedStatuses?.includes(status)) {
              setSelectedStatuses(prevData =>
                prevData?.filter(s => s !== status)
              );
            } else {
              setSelectedStatuses(prevData => [...prevData, status]);
            }
          }}
          selectedVenueIds={filters.selectedVenueIds}
          selectedRoleIds={filters.selectedRoleIds}
          toggleFilters={toggleFilters}
        />
      </ContentWrapper>

      {isShowQuickAddModal && (
        <TimesheetQuickAddModal onClose={() => setIsShowQuickAddModal(false)} />
      )}
    </Wrapper>
  );
};
export default Timesheets;
