import React, { useState } from "react";
import moment from "moment-timezone";
import {
  iconNew,
  RotaButtonIcon,
  RotaNewModal,
  ModalHeader
} from "@teamrota/rota-design";
import { Role } from "@teamrota/authlib";
import useAuth from "~/src/auth/hooks/use-auth";
import {
  TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS,
  TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS
} from "@teamrota/rota-common";

import { TimesheetBreakdownOptions } from "../timesheet-breakdown-options";
import styled from "styled-components";
import { OptionKey, BaseOptionKey, SleepOptionKey } from "../types";

import { TimesheetMemberWithStats } from "../use-timesheet-members-with-stats";
import TimesheetBreakdownModalContent from "./TimesheetBreakdownModalContent";

const { OpenEye, ChevronLeft } = iconNew;
const OptionsButton = styled(RotaButtonIcon)`
  position: relative;
`;

const ModalContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 660px;
`;

interface Props {
  timesheet: TimesheetMemberWithStats;
  onClose: () => void;
}

const getOptions = ({
  hasProviderSchedulerRole
}: {
  hasProviderSchedulerRole: boolean;
}) => {
  const optionalFields = (Object.keys(
    TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS
  ) as Array<BaseOptionKey>).filter(option =>
    hasProviderSchedulerRole
      ? true
      : !([
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PAY_RATE,
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_PAY
        ] as BaseOptionKey[]).includes(
          TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS[option]
        )
  );

  const sleepTimeFields = Object.keys(
    TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS
  ) as Array<SleepOptionKey>;

  return [...optionalFields, ...sleepTimeFields];
};

const setStoredOptions = (optionsArray: string[]) =>
  (localStorage.breakdownOptions = JSON.stringify(optionsArray));

const getStoredOptions = (hasProviderSchedulerRole: boolean) => {
  const options: BaseOptionKey[] | null = JSON.parse(
    localStorage?.breakdownOptions || null
  );
  if (hasProviderSchedulerRole) return options;

  return options?.filter(
    option =>
      !([
        TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.PAY_RATE,
        TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS.TOTAL_PAY
      ] as BaseOptionKey[]).includes(
        TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS[option]
      )
  );
};

const TimesheetBreakdownModal = ({ timesheet, onClose }: Props) => {
  const auth = useAuth();
  const hasProviderSchedulerRole = auth.hasRole(Role.PROVIDER_SCHEDULER);

  const DEFAULT_OPTIONS = getOptions({
    hasProviderSchedulerRole
  });

  const [isShowBreakdownOptions, setIsShowBreakdownOptions] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<OptionKey[]>(
    getStoredOptions(hasProviderSchedulerRole) || DEFAULT_OPTIONS
  );

  const handleOptionChange = (option: OptionKey) => {
    const newOptions = selectedOptions.includes(option)
      ? selectedOptions.filter(id => id !== option)
      : [...selectedOptions, option];

    setSelectedOptions(newOptions);
    setStoredOptions(newOptions);
  };

  const isSameDay = moment(timesheet.shiftStartTime).isSame(
    timesheet.shiftEndTime,
    "day"
  );

  const timesheetDate = isSameDay
    ? moment(timesheet.shiftStartTime).format("Do MMM YYYY")
    : `${moment(timesheet.shiftStartTime).format("Do MMM YYYY")} -
      ${moment(timesheet.shiftEndTime).format("Do MMM YYYY")}`;

  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={timesheet.shiftName || `Shift at ${timesheet.venueName}`}
          subtitle={timesheetDate}
          startAction={
            <RotaButtonIcon onClick={onClose}>
              <ChevronLeft />
            </RotaButtonIcon>
          }
          endAction={
            <OptionsButton
              onClick={() => setIsShowBreakdownOptions(!isShowBreakdownOptions)}
            >
              <OpenEye />
              {isShowBreakdownOptions && (
                <TimesheetBreakdownOptions
                  selectedOptions={selectedOptions}
                  handleOptionChange={handleOptionChange}
                  options={getOptions({
                    hasProviderSchedulerRole
                  })}
                />
              )}
            </OptionsButton>
          }
        />
      }
    >
      <ModalContentWrapper>
        <TimesheetBreakdownModalContent
          timesheet={timesheet}
          selectedOptions={selectedOptions}
          options={DEFAULT_OPTIONS}
        />
      </ModalContentWrapper>
    </RotaNewModal>
  );
};

export default TimesheetBreakdownModal;
