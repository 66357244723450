import moment from "moment-timezone";

export const validate = (values: any) => {
  const errors: any = {};

  if (!values.venueId) {
    errors.venueId = "Required";
  }

  if (isSubvenueSelectionEnforced(values)) {
    errors.subvenueId = "Required";
  }

  if (!values.roleRateId) {
    errors.roleRateId = "Required";
  }

  if (!values.selectedMembers.length) {
    errors.selectedMembers = "Select at least one member";
  }

  if (values.scheduledStartTime.isAfter(moment())) {
    errors.scheduledStartTime = "Start time cannot be in the future";
  }

  if (values.scheduledEndTime.isBefore(values.scheduledStartTime)) {
    errors.scheduledEndTime = "End time cannot be before start time";
  }

  if (values.isDifferentCheckInTime) {
    if (values.checkOutTime.isBefore(values.checkInTime)) {
      errors.checkOutTime = "End time cannot be before start time";
    }
  }

  if (values.isSleepTime) {
    if (values.sleepEndTime.isBefore(values.sleepStartTime)) {
      errors.sleepEndTime = "End time cannot be before start time";
    }

    const actualShiftStart = values.isDifferentCheckInTime
      ? values.checkInTime
      : values.scheduledStartTime;
    const actualShiftEnd = values.isDifferentCheckInTime
      ? values.checkOutTime
      : values.scheduledEndTime;

    if (values.actualSleepStartTime.isBefore(actualShiftStart)) {
      errors.actualSleepStartTime =
        "Actual sleep start time cannot be before shift start time";
    }
    if (values.actualSleepEndTime.isAfter(actualShiftEnd)) {
      errors.actualSleepEndTime =
        "Actual sleep end time cannot be after shift end time";
    }
    if (values.actualSleepEndTime.isBefore(values.actualSleepStartTime)) {
      errors.actualSleepEndTime = "End time cannot be before start time";
    }

    if (values.sleepStartTime.isBefore(values.scheduledStartTime)) {
      errors.sleepStartTime =
        "Sleep start time cannot be before shift start time";
    }
    if (values.sleepEndTime.isAfter(values.scheduledEndTime)) {
      errors.sleepEndTime = "Sleep end time cannot be after shift end time";
    }
  }

  if (values.isDisturbedHours) {
    if (values.disturbedHoursEnd.isBefore(values.disturbedHoursStart)) {
      errors.disturbedHoursEnd = "End time cannot be before start time";
    }
    if (values.disturbedHoursStart.isBefore(values.actualSleepStartTime)) {
      errors.disturbedHoursStart =
        "Disturbed hours start time cannot be before actual sleep start time";
    }
    if (values.disturbedHoursEnd.isAfter(values.actualSleepEndTime)) {
      errors.disturbedHoursEnd =
        "Disturbed hours end time cannot be after actual sleep end time";
    }
  }

  return errors;
};

const isSubvenueSelectionEnforced = (values: any) => {
  return (
    values.subvenues.length > 0 &&
    values.venueId &&
    !values.subvenueId &&
    values.isSubvenueSelectionEnforced
  );
};
