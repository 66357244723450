import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { flow, find, sortBy, omit } from "lodash/fp";

import { ZonedDate } from "@teamrota/rota-common";
import { Role } from "@teamrota/authlib";

import { SHIFT_SOURCES } from "~/src/consts";
import { prefillToToday } from "~/src/utils/time-utils";

import useAuth from "~/src/auth/hooks/use-auth";
import asyncConfirm from "~/src/utils/async-confirm";

import SHIFTS_QUERY from "~/src/graphql/queries/get-shifts/get-shifts.query";
import getProfile from "~/src/graphql/queries/get-profile/get-profile-query.decorator";

import Money from "~/src/utils/money";
import MoneyTransaction from "~/src/utils/money/money-transaction";
import { checkErrors, errorModal } from "~/src/utils/errors";
import { getPolicyQueryParams } from "~/src/utils/formatting";
import {
  getDefaultShiftPostProps,
  getIfTimesheetModalShouldBeShown
} from "~/src/utils";

import { hideMemberDetails } from "~/src/containers/pools/reducer";
import MemberModal from "../pools/components/member-modal";

import {
  getLastCalendarWeekDate,
  setLastCalendarWeekDate,
  hasSeenTimesheet,
  markHasSeenTimesheet
} from "./session";

import * as utils from "./utils";

import {
  postShift,
  cancelPostShift,
  clearBonusState,
  addShift
} from "./reducer";

import createOrUpdateShift from "./graphql/create-or-update-shift";
import createOrUpdateBonusReward from "../../graphql/mutations/bonus-rewards/create-or-update-bonus-reward";

import CalendarGrid from "./CalendarGrid";
import CalendarHeader from "./CalendarHeader";
import ShiftCreateModal from "./ShiftCreateModal";
import { CalendarContainer } from "./styles";
import ShiftModal from "./shift-modal";
import TimesheetConfirmModal from "./TimesheetConfirmModal";
import ManageShiftsSideBar from "./manage-shifts-sidebar";
import FooterLegend from "./FooterLegend";

const RequesterCalendar = ({
  dispatch,
  user,
  isLoadingAccount,
  shiftForm,
  policy, // how can this ever not be null?
  selectedMemberId,
  bonuses,
  createOrUpdateShift,
  createOrUpdateBonusReward,
  isAddShift = false,
  refetchProfile
}) => {
  const history = useHistory();
  const auth = useAuth();
  const containerRef = useRef();

  const [calendarWeekDate, setCalendarWeekDate] = useState(
    getLastCalendarWeekDate() ||
      moment()
        .startOf("isoweek")
        .toDate()
  );

  const [shouldShowCancelled, setShouldShowCancelled] = useState(true);
  const [isPostingShift, setIsPostingShift] = useState(false);
  const [isLoadingPostShift, setIsLoadingPostShift] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [shiftModalShiftId, setShiftModalShiftId] = useState(null);
  const [shiftTotalInGroup, setShiftTotalInGroup] = useState(null);
  const [showTimesheet, setShowTimesheet] = useState(!hasSeenTimesheet());

  const hasOldTimesheets = auth.hasRoles(
    Role.REQUESTER,
    Role.TIMESHEETS,
    Role.TIMESHEETS_OLD
  );

  const hasNewTimesheets =
    auth.hasRoles(Role.REQUESTER, Role.TIMESHEETS) &&
    (user?.account?.newTimesheetsStartDate ||
      user?.account.requesterConnections?.data[0]?.sourceAccount
        .newTimesheetsStartDate);

  const shouldShowOldTimesheets =
    hasOldTimesheets &&
    !hasNewTimesheets &&
    auth.hasRoles(Role.REQUESTER, Role.TIMESHEETS);

  const { loading: isShiftsLoading, data: shiftsData } = checkErrors(
    useQuery(SHIFTS_QUERY, {
      fetchPolicy: "network-only",
      variables: auth.addVals(SHIFTS_QUERY, {
        shiftSources: [SHIFT_SOURCES.REQUESTED, SHIFT_SOURCES.PROVIDED],
        withBookings: false,
        bookingsLimit: 0,
        bookingsOffset: 0,
        limit: 1000,
        offset: 0,
        accountType: "requester",
        overlapsWeek: calendarWeekDate.toString()
      })
    })
  );

  useEffect(() => {
    if (user?.account?.outstandingTimesheets) {
      const oustanding = user?.account?.outstandingTimesheets;
      if (oustanding.length) {
        const timesheet = getIfTimesheetModalShouldBeShown(
          sortBy(({ endTime }) => new ZonedDate(endTime), oustanding).shift()
        );

        if (timesheet && !hasSeenTimesheet() && shouldShowOldTimesheets) {
          markHasSeenTimesheet();
          setShowTimesheet(timesheet);
          return;
        }
      }
    }

    setShowTimesheet(null);
  }, [user?.account?.outstandingTimesheets?.length]);

  useEffect(() => {
    // update local storage
    setLastCalendarWeekDate(calendarWeekDate);
  }, [calendarWeekDate]);

  const handleDateChangeRequest = (
    change, // -1 | 0 | 1
    shouldResetDragState = true
  ) => {
    if (shouldResetDragState) {
      if (change === 0) {
        setCalendarWeekDate(
          moment()
            .startOf("isoweek")
            .toDate()
        );
      } else {
        setCalendarWeekDate(
          moment(calendarWeekDate)
            .add(change, "w")
            .toDate()
        );
      }
    } else if (change === 1) {
      setCalendarWeekDate(
        moment(calendarWeekDate)
          .add(change, "w")
          .toDate()
      );
    }
  };

  const handleSaveShift = async () => {
    const shifts = shiftForm.shifts.filter(s => !s.isDeleted);

    const policyShift = find(
      shift => utils.getIsPolicyApplied(policy, shift),
      shifts
    );

    if (
      !policyShift ||
      (await asyncConfirm("Are you sure you want to post this shift?", {
        confirmButtonText: "Post",
        falseButtonText: "Back",
        isConfirmButtonGreen: true,
        subComponent: () => (
          <span>
            The shift length is {utils.getHours(policyShift)} hours so a minimum
            of {policy.minimumShiftLength} hours will be charged in accordance
            with our{" "}
            <a href={getPolicyQueryParams(policy)} target="_blank">
              Minimum Shift Length Policy.
            </a>
          </span>
        )
      }))
    ) {
      try {
        utils.validateShift(shifts);

        for (const shift of shifts) {
          const formattedData = {
            ...shiftForm,
            shifts: omit(["isUncalculatedRate", "briefingId"], shift)
          };
          setIsLoadingPostShift(true);
          const cleanedData = utils.cleanDataForSave(formattedData);
          const savedShift = await createOrUpdateShift(cleanedData);

          if (auth.hasAnyRole(Role.SHIFTS_COST, Role.SHIFTS_PAY)) {
            await addBonusToShift(savedShift.data.createOrUpdateShift);
          }
        }

        setIsPostingShift(false);
        setIsLoadingPostShift(false);
        refetchProfile();
        await dispatch(cancelPostShift());
      } catch (e) {
        await errorModal(e.message);
      }
    }
  };

  const addBonusToShift = async createdShifts => {
    try {
      if (bonuses && find({ shiftIndex: shiftForm.shiftOpenIndex }, bonuses)) {
        const currentBonuses =
          find({ shiftIndex: shiftForm.shiftOpenIndex }, bonuses)?.items ?? [];

        await Promise.all(
          currentBonuses
            .filter(item => item.type && item.amount)
            .map(async bonus => {
              const bonusAmount = new MoneyTransaction("addBonusToShift");
              bonusAmount.add(Money({ amount: bonus.amount }));

              await Promise.all(
                createdShifts.map(shift =>
                  createOrUpdateBonusReward({
                    shiftId: shift.id,
                    amount: bonusAmount.current.getAmount(),
                    period: bonus.type,
                    type: "bonus"
                  })
                )
              );
            })
        );
      }
    } catch (e) {
      console.log("There was a problem adding the bonus/es to the shift: ", e);
    } finally {
      await dispatch(clearBonusState());
    }
  };

  const handleEventClick = calendarEvent => {
    setShiftModalShiftId(calendarEvent.data.id);
    setShiftTotalInGroup(calendarEvent.shifts[0].totalInGroup);
  };

  const handleClosePopUp = async () => {
    if (
      await asyncConfirm("Do you want to cancel this request?", {
        shouldHideSubText: true,
        shouldAddPadding: true,
        confirmButtonText: "Yes, cancel this request"
      })
    ) {
      setIsPostingShift(false);
      await dispatch(cancelPostShift());
      await dispatch(clearBonusState());
    }
  };

  const handleShowCancelShifts = () =>
    setShouldShowCancelled(!shouldShowCancelled);

  const handleNewShift = () => dispatch(addShift());

  // extra prop indicates the add button was clicked on the sidebar
  if (isAddShift && !isPostingShift) {
    history.push("/request");

    dispatch(
      postShift({
        ...getDefaultShiftPostProps(user),
        ...prefillToToday()
      })
    );

    setIsPostingShift(true);
  }

  const roleOptions = user?.account?.roleRates
    ? utils.createRoleRateOptions({
        roleRates: user.account.roleRates,
        accountId: utils.getTargetAccount(user.account.requesterConnections),
        shift: null,
        allRoles: true
      })
    : {};

  const dayRoleOptions = user?.account?.roleRates
    ? utils.createRoleRateOptions({
        roleRates: user.account.roleRates,
        accountId: utils.getTargetAccount(user.account.requesterConnections),
        shift: null,
        isDayRoles: true
      })
    : {};

  const calculatedRoleOptions = user?.account?.roleRates
    ? utils.createRoleRateOptions({
        roleRates: user.account.roleRates,
        accountId: utils.getTargetAccount(user.account.requesterConnections),
        isUncalculatedRate: false
      })
    : {};

  const uncalculatedRoleOptions = user?.account?.roleRates
    ? utils.createRoleRateOptions({
        roleRates: user.account.roleRates,
        accountId: utils.getTargetAccount(user.account.requesterConnections),
        isUncalculatedRate: true
      })
    : {};

  const targetAccountId =
    user?.account?.requesterConnections &&
    utils.getTargetAccount(user.account.requesterConnections);

  const venueOptions =
    user?.venueIds?.length > 0
      ? user?.account?.venues.filter(({ id }) =>
          user?.venueIds.includes(parseInt(id))
        )
      : user?.account?.venues;

  const fetchedLastVenueId = user?.account?.lastUsedVenue?.[0]?.id;
  const isLastVenueIdPresent = utils.checkIfLastUsedExistInOptions(
    venueOptions,
    fetchedLastVenueId
  );

  const lastUsedVenueId = isLastVenueIdPresent ? fetchedLastVenueId : null;

  const sortedVenueOptions = lastUsedVenueId
    ? utils.sortOptionsMakingLastUsedFirst(venueOptions, lastUsedVenueId)
    : venueOptions;

  const timesheetHoursToConfirm =
    showTimesheet &&
    moment(showTimesheet.endTime)
      .add(48, "hours")
      .diff(moment(), "hours");

  const timesheetUnlocked =
    showTimesheet && moment(showTimesheet.endTime).isoWeekday() >= 5;

  return (
    <CalendarContainer ref={containerRef}>
      <CalendarHeader
        onDateChange={handleDateChangeRequest}
        calendarWeekDate={calendarWeekDate}
        handleShowCancelShifts={handleShowCancelShifts}
        shouldShowCancelled={shouldShowCancelled}
        onToggleSideBar={() => setIsSideBarOpen(!isSideBarOpen)}
        isMinimumOnePartnerManagedConnection={
          user?.account?.isMinimumOnePartnerManagedConnection || false
        }
        managableShiftsCount={user?.account?.managableShiftsCount}
      />
      <CalendarGrid
        isLoading={isShiftsLoading || isLoadingAccount}
        container={containerRef}
        shifts={shiftsData?.account?.shifts?.data ?? []}
        shouldShowCancelled={shouldShowCancelled}
        changeDate={handleDateChangeRequest}
        calendarWeekDate={calendarWeekDate}
        canDragNewShifts={
          auth.hasRole(Role.SHIFTS_CREATE) &&
          !isPostingShift &&
          !shiftModalShiftId
        }
        onDraggedShift={({ startTime, endTime }) => {
          if (!isPostingShift) {
            const props = {
              startTime: startTime > endTime ? endTime : startTime,
              endTime: startTime > endTime ? startTime : endTime,
              isShiftDragged: true,
              ...getDefaultShiftPostProps(user)
            };

            dispatch(postShift(props));
            setIsPostingShift(true);
          }
        }}
        onEventClick={handleEventClick}
      />
      <ShiftCreateModal
        onClose={handleClosePopUp}
        onSave={handleSaveShift}
        onNewShift={handleNewShift}
        isOpen={isPostingShift}
        isLoadingPostShift={isLoadingPostShift}
        targetAccountId={targetAccountId}
        roleOptions={roleOptions}
        dayRoleOptions={dayRoleOptions}
        calculatedRoleOptions={calculatedRoleOptions}
        uncalculatedRoleOptions={uncalculatedRoleOptions}
        venueOptions={sortedVenueOptions}
        lastUsedVenueId={lastUsedVenueId}
        targetAccount={user?.account}
        briefingTemplates={user?.account?.briefingTemplates}
        connections={user?.account?.requesterConnections}
        roleRates={user?.account?.roleRates ?? []}
        uniformOptions={user?.account?.uniformTemplates ?? []}
        isAccountSleepTimeEnabled={user?.account?.isSleepTimeEnabled}
        policy={policy}
      />
      {selectedMemberId && user?.account && (
        <MemberModal
          isOpen={!!selectedMemberId}
          onClose={() => dispatch(hideMemberDetails())}
          memberId={selectedMemberId}
          sourceAccountId={user.account.id}
        />
      )}
      <ShiftModal
        policy={policy}
        shiftId={shiftModalShiftId}
        totalInGroup={shiftTotalInGroup}
        onClose={() => setShiftModalShiftId(null)}
        targetAccountId={targetAccountId}
      />
      {showTimesheet && (
        <TimesheetConfirmModal
          isOpen={showTimesheet}
          hoursToConfirm={timesheetHoursToConfirm}
          totalOutstandingTimesheets={
            user?.account?.outstandingTimesheets?.length
          }
          isUnlocked={timesheetUnlocked}
          onClose={() => {
            setShowTimesheet(null);
          }}
          onConfirm={() => {
            setShowTimesheet(null);
            history.push("/timesheets");
          }}
        />
      )}
      <ManageShiftsSideBar
        isOpen={isSideBarOpen}
        onToggleSideBar={() => setIsSideBarOpen(!isSideBarOpen)}
        onOpenShift={shift => {
          setShiftModalShiftId(shift.id);
          setIsSideBarOpen(!isSideBarOpen);
        }}
      />
      <FooterLegend />
    </CalendarContainer>
  );
};

export default flow(
  Component => getProfile(Component, { isRequestPage: true }),
  createOrUpdateBonusReward,
  createOrUpdateShift,
  connect(s => ({
    shiftForm: s.calendar.shiftForm,
    selectedConnectionId: s.calendar.selectedConnectionId,
    selectedMemberId: s.pools.selectedMemberId,
    bonuses: s.calendar.bonuses
  }))
)(RequesterCalendar);
