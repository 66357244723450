import React from "react";
import moment from "moment-timezone";

import { Role } from "@teamrota/authlib";

import { PARTNER_MANAGED_THRESHOLD } from "~/src/consts";
import { formatRoleTime } from "../../utils";

import HasRole from "~/src/containers/has-role";
import { formatTimeRemainingToManage } from "~/src/utils/formatting";

import PrintableStaffList from "~/src/components/printable/staff-list";
import printComponent from "~/src/utils/print-component";
import CancelledShiftPlaceholder from "./components/cancelled-shift-placeholder";
import MemberGrid from "./components/member-grid";

import StreamInfo from "../stream-info";

import {
  GridContainer,
  HeaderWrapper,
  Heading,
  ModalHeader,
  ShiftTitle,
  ShiftTitleDim,
  ShiftTitleDimRestricted,
  StyledButton,
  StyledIcon
} from "./shift-modal-details.styles";

const ShiftModalDetails = ({ shift, isVisible, onEdit, showMember }) => {
  const shiftAfter24Hours = () => {
    const THRESHOLD_24_HOUR = 24;
    return !(
      moment(shift?.startTime).diff(moment(), "hours") < THRESHOLD_24_HOUR
    );
  };

  if (!isVisible) return false;

  const isPartnerManaged =
    shift?.isPartnerManaged &&
    moment()
      .add(PARTNER_MANAGED_THRESHOLD, "days")
      .isBefore(moment(shift?.startTime));

  const onPrint = bookings => {
    printComponent(
      <PrintableStaffList shift={shift} timesheetBookings={bookings} />
    );
  };

  const isCancelled = shift?.cancelledAt;

  const isRestricted =
    !shift?.isShiftRebook &&
    (shift?.isRestricted || shift?.delayHoursAlgoShift === 24);

  const isRebook = shift?.isShiftRebook;

  const isStream = !!shift?.stream;

  return (
    <div>
      <ModalHeader>
        <HeaderWrapper shouldFlex>
          <ShiftTitleDim>ID# {shift?.identifier || shift?.id}</ShiftTitleDim>
          <ShiftTitle>{shift.name ?? "Untitled shift"}</ShiftTitle>
          <ShiftTitleDimRestricted>
            {isRebook && "(Rebooked shift)"}
            {isRestricted && "(restricted)"}
          </ShiftTitleDimRestricted>
          <ShiftTitleDim>{shift?.venue?.name ?? "No venue name"}</ShiftTitleDim>
          <ShiftTitleDim>{formatRoleTime(shift)}</ShiftTitleDim>
        </HeaderWrapper>

        <HasRole role={Role.SHIFTS_EDIT}>
          {(shift || !isCancelled) && (
            <HeaderWrapper>
              <StyledButton onClick={() => onEdit(shiftAfter24Hours())}>
                Edit
              </StyledButton>
            </HeaderWrapper>
          )}
        </HasRole>
      </ModalHeader>
      {isStream && <StreamInfo shift={shift} />}
      {!isCancelled && isPartnerManaged && (
        <Heading isContainer>
          <StyledIcon />
          <Heading>
            {`${formatTimeRemainingToManage(shift?.startTime)} left to manage `}
          </Heading>
        </Heading>
      )}
      <GridContainer>
        {isCancelled && <CancelledShiftPlaceholder />}

        {!isCancelled &&
          shift &&
          Object.keys(shift?.bookings).map((bookingState, index) => (
            <MemberGrid
              key={index}
              onPrint={onPrint}
              shiftCreatedAt={shift.postedAt}
              bookingState={bookingState}
              bookings={shift?.bookings[bookingState]}
              showMember={showMember}
              shift={shift}
            />
          ))}
      </GridContainer>
    </div>
  );
};

export default ShiftModalDetails;
