import styled from "styled-components";

export const OnCallTimeTable = styled.table`
  padding: 10px 0 35px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.error.main};
`;

export const TableHeader = styled.p`
  text-align: center;
  margin: 0;
`;

export const DateTime = styled.div`
  width: 50px;
  flex: none;

  input {
    text-align: center;
    border-radius: 0;
    border-bottom: 1px solid transparent;
    padding: 3px;
  }

  input:hover,
  input:focus {
    border-bottom: 1px solid ${({ theme }) => theme.primary.main};
  }
`;
