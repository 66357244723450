import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { uniqBy } from "lodash";

import { RotaButton, RotaTab } from "@teamrota/rota-design";
import { POOL_TYPES } from "@teamrota/rota-common";

import { ROLE_TABS } from "~/src/consts";
import asyncConfirm from "~/src/utils/async-confirm";
import RateBreakdown from "~/src/components/RateBreakdown";
import Select from "~/src/components/form-components/select";
import TextInput from "~/src/components/form-components/text-input";
import MemberAddWarningModal from "~/src/components/StaffZonesPanel/components/MemberAddWarningModal";
import { useVenueRoleTags } from "~/src/graphql/queries/use-venue-role-tags/use-venue-role-tags";

import ShiftTagsViewModal from "./ShiftTagsViewModal";
import { useTagsForAccount } from "./use-tags-for-account";
import { useGetRelatedMember } from "./use-get-related-member";
import FormStepSegment from "../FormStepSegment";
import { useValidateAudience } from "../shared";
import { getMemberTypesDropdownValues } from "../../utils";

import { TabsWrapper, TabsTitle, RoleAndCountWrapper } from "./styles";
import { Actions, Fieldset, Header, HeaderTitle, EditButton } from "../styles";

import {
  useDayRoleOptions,
  useCalculatedRoleOptions,
  useUncalculatedRoleOptions,
  useVenueOptions,
  useUpdateRoleRate,
  useUpdateVenue,
  useUpdateMemberType,
  getFormTitle,
  mapStateToProps,
  useUpdateSubvenue
} from "./selectors";
import useAuth from "~/src/auth/hooks/use-auth";
import { Role } from "@teamrota/authlib";
import { useHomeVenues } from "~/src/containers/provide-schedule/use-home-venues";

const FormAudience = props => {
  const {
    shift,
    isProviderScheduler,
    selectedAccountId,
    isOpen,
    hasVisited,
    hasCompletedAllSteps,
    goToSelf,
    goToNext,
    handleShiftUpdate,
    stepNumber,
    isGridSelectedShift,
    filteredPartners,
    selectedPartnerId,
    setSelectedPartnerId,
    requesterProfileLoading,
    targetAccountId
  } = props;

  const {
    tagsAccountName,
    tagsAvailableForShift,
    tagsCompulsoryForShift
  } = useTagsForAccount({
    roleRateId: shift.roleRateId,
    roleRateAccountId: shift?.privates?.roleRateAccount?.id,
    partnerAccountId: selectedPartnerId
  });

  const auth = useAuth();

  const isSelfProvider = auth.hasRoles(Role.PROVIDER, Role.REQUESTER);

  const { homeVenues, accountId } = useHomeVenues();

  const homeVenueIds = homeVenues.map(venue => venue.id);
  const { venuesWithTags } = useVenueRoleTags(
    selectedPartnerId || targetAccountId
  );

  const assignedMemberId = shift.assignedMemberIds?.[0];

  const [isDayRoles, setIsDayRoles] = useState(false);
  const [isRoleSelected, setIsRoleSelected] = useState(false);
  const [selectedTab, setSelectedTab] = useState(ROLE_TABS[0]);
  const [blacklistWarning, setBlacklistWarning] = useState(null);

  const partnersWithUncalculatedRates = filteredPartners?.filter(partner =>
    partner.roleRates.some(({ isUncalculatedRate }) => isUncalculatedRate)
  );

  const partnersWithCalculatedRates = filteredPartners?.filter(partner =>
    partner.roleRates.some(({ isUncalculatedRate }) => !isUncalculatedRate)
  );

  const partnersToDisplay =
    selectedTab === "Uncalculated"
      ? partnersWithUncalculatedRates
      : partnersWithCalculatedRates;

  const partnerDropdownValues = partnersToDisplay?.map(p => ({
    value: p.id,
    label: p.accountName
  }));

  const defaultSelectedPartnerId =
    filteredPartners?.length === 1 ? filteredPartners[0].id : selectedPartnerId;

  const handleTabSwitch = tab => {
    setSelectedTab(tab);
    setIsRoleSelected(false);
    handleShiftUpdate({
      roleRateId: ""
    });

    if (isProviderScheduler && filteredPartners?.length !== 1) {
      setSelectedPartnerId(null);
      handleShiftUpdate({
        venueId: ""
      });
    }
  };

  const { member, loading: relationshipLoading } = useGetRelatedMember({
    assignedMemberId,
    selectedAccountId,
    partnerId: isProviderScheduler
      ? defaultSelectedPartnerId
      : props?.connections?.data[0]?.targetAccount?.id
  });

  const checkRelationship = () => {
    if (member?.relationship?.poolType === POOL_TYPES.BLACKLISTED) {
      setBlacklistWarning({
        member,
        callback: () => goToNext()
      });
    } else {
      goToNext();
    }
  };

  const handleTagsModalOpen = async () => {
    const initialSelectedTags = shift.tags;

    const confirmResult = await asyncConfirm(
      "Select the tags to add to this shift",
      {
        confirmButtonText: "Save",
        falseButtonText: "Cancel",
        isConfirmButtonGreen: true,
        subComponent: () => (
          <ShiftTagsViewModal
            tagsAvailableForShift={tagsAvailableForShift}
            tagsCompulsoryForShift={compulsoryShiftTags}
            selectedShiftTags={shift.tags}
            handleShiftUpdate={handleShiftUpdate}
            tagsAccountName={tagsAccountName}
          />
        )
      }
    );

    if (!confirmResult) {
      handleShiftUpdate({ tags: initialSelectedTags });
    }
  };

  const dayRoleOptions = useDayRoleOptions(props);
  const calculatedRoleOptions = useCalculatedRoleOptions(props);
  const uncalculatedRoleOptions = useUncalculatedRoleOptions(props);
  const venueWithoutExclusion = useVenueOptions(props);

  const venueOptions = venueWithoutExclusion.reduce((acc, defaultVenue) => {
    const isExisting = member?.excludedVenueIds?.some(
      id => id == defaultVenue.value
    );

    if (!isExisting) {
      acc.push(defaultVenue);
    }
    return acc;
  }, []);

  const selfProviderVenueOptions = venueOptions.filter(venue =>
    homeVenueIds.includes(venue.value)
  );

  const selectedAccountVenueOptions =
    isSelfProvider && selectedPartnerId === accountId
      ? selfProviderVenueOptions
      : venueOptions;

  const getSubvenueOptions = () => {
    let defaultSubvenueOption = {
      value: null,
      label: "None"
    };

    const venueSubvenues =
      props.venueOptions.find(v => v.id === shift.venueId)?.subvenues || [];

    if (!venueSubvenues.length) {
      defaultSubvenueOption.label = "No Sub-venues";
    }

    const subvenueOptions = venueSubvenues.map(subvenue => ({
      value: subvenue.id,
      label: subvenue.name
    }));

    const sortedSubvenueOptions = subvenueOptions.sort((a, b) =>
      a.label.toLowerCase().localeCompare(b.label.toLowerCase())
    );

    return [defaultSubvenueOption, ...sortedSubvenueOptions];
  };

  const onRoleRateChange = useUpdateRoleRate(props);
  const onMemberTypeChange = useUpdateMemberType(props);
  const onVenueChange = useUpdateVenue(props);
  const onSubvenueChange = useUpdateSubvenue(props);
  const isAudienceValid = useValidateAudience(props);

  const getOptions = () => {
    let options;
    if (selectedTab === "Uncalculated") {
      options = uncalculatedRoleOptions;
    } else if (isDayRoles) {
      options = dayRoleOptions;
    } else {
      options = calculatedRoleOptions;
    }

    const shiftStartTime = moment(Object.values(shift.times)?.[0]?.startTime);

    const optionsArray = Object.values(options)[0];

    const filteredOptions = optionsArray?.filter(
      option =>
        shiftStartTime.isAfter(option.startDate) &&
        (shiftStartTime.isBefore(option.endDate) || !option.endDate)
    );

    return filteredOptions || [];
  };

  const roleOptions = getOptions();

  const roleRateToRoleId = roleOptions?.find(
    ({ value }) => value === shift.roleRateId
  )?.roleId;

  const venueTags =
    venuesWithTags?.find(({ id }) => id === shift?.venueId)?.tags || [];
  const venueRoleTags =
    venuesWithTags
      ?.find(({ id }) => id === shift?.venueId)
      ?.roleTags?.find(({ roleId }) => roleId === roleRateToRoleId)?.tags || [];

  const compulsoryShiftTags = uniqBy(
    [...venueTags, ...venueRoleTags, ...tagsCompulsoryForShift],
    "id"
  );

  const saveCompulsoryTagsToShift = () => {
    const tags =
      compulsoryShiftTags?.map(({ id, name }) => ({
        id,
        name,
        isCompulsory: true
      })) || [];

    handleShiftUpdate({
      tags
    });
  };

  useEffect(() => {
    if (shift?.venueId || shift?.roleRateId) saveCompulsoryTagsToShift();
  }, [shift?.venueId, shift?.roleRateId]);

  useEffect(() => {
    if (isProviderScheduler && filteredPartners.length === 1) {
      setSelectedPartnerId(filteredPartners[0].id);
    }
  }, []);

  const shouldShowTagsButton =
    shift.roleRateId &&
    shift.venueId &&
    (tagsAvailableForShift?.length !== 0 || compulsoryShiftTags?.length !== 0);

  return (
    <FormStepSegment
      isOpen={isOpen}
      stepNumber={stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>
            {!!isAudienceValid && !isOpen
              ? getFormTitle(props)
              : "Staff Requirements"}
          </HeaderTitle>
          {!!hasVisited && !isOpen && (
            <EditButton onClick={goToSelf}>
              {isAudienceValid ? "Edit" : "Missing Info!"}
            </EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <Fieldset>
            {selectedTab === "Calculated" && (
              <TabsWrapper>
                <TabsTitle>Choose a type of rate:</TabsTitle>
                <RotaTab
                  label="Hourly Rate"
                  onClick={() => {
                    setIsDayRoles(false);
                    setIsRoleSelected(false);
                  }}
                  size="sm"
                  isActive={!isDayRoles}
                />
                <RotaTab
                  label="Daily Rate"
                  onClick={() => {
                    setIsDayRoles(true);
                    setIsRoleSelected(false);
                  }}
                  size="sm"
                  isActive={isDayRoles}
                />
              </TabsWrapper>
            )}
            <TabsWrapper>
              <TabsTitle>Choose a type of role:</TabsTitle>
              {ROLE_TABS.map(tab => (
                <RotaTab
                  key={tab}
                  label={tab}
                  onClick={() => handleTabSwitch(tab)}
                  size="sm"
                  isActive={selectedTab === tab}
                />
              ))}
              <RotaButton
                variant="outlined"
                size="small"
                onClick={handleTagsModalOpen}
                disabled={!shouldShowTagsButton}
                style={{ marginLeft: "auto" }}
              >
                Edit Tags
              </RotaButton>
            </TabsWrapper>
            <RoleAndCountWrapper oneColumn={!isProviderScheduler}>
              {isProviderScheduler && (
                <Select
                  label="Select the partner"
                  isDisabled={filteredPartners.length === 1}
                  options={partnerDropdownValues}
                  shouldFixLabelHeight
                  placeholder="Partner"
                  value={defaultSelectedPartnerId}
                  onChange={value => {
                    setSelectedPartnerId(value);
                    handleShiftUpdate({
                      venueId: "",
                      roleRateId: ""
                    });
                  }}
                  isSmall
                />
              )}
              <Select
                isDisabled={!!isGridSelectedShift}
                label="Select the type of staff"
                options={getMemberTypesDropdownValues()}
                shouldFixLabelHeight
                placeholder="Staff Type"
                value={shift.memberType}
                onChange={onMemberTypeChange}
                isSmall
              />
            </RoleAndCountWrapper>
            <RoleAndCountWrapper>
              {!isGridSelectedShift && (
                <TextInput
                  label="How many do you need?"
                  isSmall
                  min={1}
                  isError={parseInt(shift.numberRequested || 0, 10) <= 0}
                  value={shift.numberRequested}
                  onChangeValue={numberRequested => {
                    handleShiftUpdate({
                      numberRequested
                    });
                  }}
                  shouldFixLabelHeight
                  type="number"
                />
              )}
              <Select
                label="Select the type of Role"
                options={roleOptions}
                shouldFixLabelHeight
                placeholder="Job Role"
                isLoading={requesterProfileLoading}
                isDisabled={isProviderScheduler && !selectedPartnerId}
                value={shift.roleRateId}
                onChange={roleRateId => {
                  setIsRoleSelected(true);
                  onRoleRateChange(roleRateId);
                }}
                isSmall
              />
            </RoleAndCountWrapper>

            <Select
              label="Select the Location"
              options={selectedAccountVenueOptions}
              shouldFixLabelHeight
              placeholder="Location"
              isLoading={requesterProfileLoading}
              isDisabled={isProviderScheduler && !selectedPartnerId}
              value={shift.venueId}
              onChange={onVenueChange}
              isSmall
            />
            <Select
              label="Select a subvenue"
              options={getSubvenueOptions()}
              shouldFixLabelHeight
              placeholder="Subvenue"
              isLoading={requesterProfileLoading}
              isDisabled={!shift.venueId}
              value={shift.subvenueId}
              onChange={onSubvenueChange}
              isSmall
            />
            {isRoleSelected && (
              <RateBreakdown
                roleRateId={shift?.roleRateId}
                handleShiftUpdate={handleShiftUpdate}
              />
            )}
          </Fieldset>

          <Actions>
            <RotaButton
              {...(!!isAudienceValid && { onClick: checkRelationship })}
              disabled={!isAudienceValid || relationshipLoading}
            >
              {hasCompletedAllSteps ? "Done" : "Next"}
            </RotaButton>
          </Actions>

          <MemberAddWarningModal
            accountType={isProviderScheduler ? "provider" : "requester"}
            roleRateId={shift.roleRateId}
            warning={blacklistWarning}
            onClose={() => setBlacklistWarning(null)}
          />
        </div>
      )}
    </FormStepSegment>
  );
};

export default connect(mapStateToProps)(FormAudience);
