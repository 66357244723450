import React, { useState } from "react";
import styled from "styled-components";
import {
  RotaButton,
  RotaCheckbox,
  RotaDropdown,
  RotaInput,
  useToast
} from "@teamrota/rota-design";
import { useMutation } from "@apollo/client";
import { UPDATE_ROLE_RATE } from "./graphql/update-role-rate";

const ContentWrapper = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  gap: 16px;
`;

export const ValueCell = styled.div`
  padding-left: 15px;
  color: ${({ theme }) => theme.text.primary};
  font-size: 16px;
  ${({ isCenterAligned }) =>
    isCenterAligned && `text-align: center; padding-left: 0; `}
`;

export const LabelCell = styled.div`
  padding: 15px;
  padding-bottom: 0;
  color: ${({ theme }) => theme.neutral.main};
  font-size: 14px;
`;

export const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding: 15px 0;
  overflow: none;
`;

export const StyledWrapSubtitle = styled.div`
  white-space: nowrap;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.neutral.lighter};
  border-top-color: transparent;
  border-left-color: transparent;
  font-weight: bold;
  padding: 10px;
  display: flex;
  align-items: center;
`;

const DropdownWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

const RoleRateDetails = ({
  roleRate: initialRoleRate,
  divisions,
  consultants
}) => {
  const [roleRate, setRoleRate] = useState(initialRoleRate);
  const [hasChanges, setHasChanges] = useState(false);

  const [updateRoleRate, { loading: isSaving }] = useMutation(UPDATE_ROLE_RATE);

  const { showToast } = useToast();

  const handleChange = (field, value) => {
    setRoleRate(prev => {
      const newRoleRate = { ...prev };
      if (field === "division") {
        newRoleRate.division = { ...prev.division, id: value };
      } else if (field === "consultant") {
        newRoleRate.consultant = { ...prev.consultant, id: value };
      } else {
        newRoleRate[field] = value;
      }

      console.log("newRoleRate", newRoleRate);

      return newRoleRate;
    });
    setHasChanges(true);
  };

  const handleSave = async () => {
    try {
      await updateRoleRate({
        variables: {
          roleRateId: roleRate.id,
          divisionId: roleRate.division?.id,
          consultantId: roleRate.consultant?.id,
          sageRef: roleRate.sageRef,
          canCandidatesEditBreaks: roleRate.canCandidatesEditBreaks,
          isOnCallShiftsEnabled: roleRate.isOnCallShiftsEnabled
        }
      });

      setHasChanges(false);
      showToast("Changes saved successfully.", { severity: "success" });
    } catch (error) {
      showToast(error.message, { severity: "error" });
    }
  };

  return (
    <ContentWrapper>
      <StyledGrid>
        <LabelCell>Division Name</LabelCell>
        <LabelCell>Division Assignee</LabelCell>
        <LabelCell>Division Sage Reference</LabelCell>
        <LabelCell>Enable Candidates To Edit Break Times</LabelCell>
        <LabelCell>Enable on-call Shifts</LabelCell>
        <ValueCell>
          <DropdownWrapper>
            <RotaDropdown
              value={roleRate.division?.id}
              placeholder="Select a division"
              options={divisions.map(div => ({
                label: div.name,
                value: div.id
              }))}
              onChange={value => handleChange("division", value)}
            />
          </DropdownWrapper>
        </ValueCell>
        <ValueCell>
          <DropdownWrapper>
            <RotaDropdown
              value={roleRate.consultant?.id}
              placeholder="Select an assignee"
              options={consultants.map(cons => ({
                label: `${cons.firstName} ${cons.lastName}`,
                value: cons.id
              }))}
              onChange={value => handleChange("consultant", value)}
            />
          </DropdownWrapper>
        </ValueCell>
        <ValueCell>
          <RotaInput
            autocomplete="off"
            value={roleRate.sageRef}
            onChange={e => handleChange("sageRef", e.target.value)}
          />
        </ValueCell>
        <ValueCell isCenterAligned>
          <RotaCheckbox
            isChecked={roleRate.canCandidatesEditBreaks}
            isDisabled={false}
            onClick={() =>
              handleChange(
                "canCandidatesEditBreaks",
                !roleRate.canCandidatesEditBreaks
              )
            }
          />
        </ValueCell>
        <ValueCell isCenterAligned>
          <RotaCheckbox
            isChecked={roleRate.isOnCallShiftsEnabled}
            isDisabled={false}
            onClick={() =>
              handleChange(
                "isOnCallShiftsEnabled",
                !roleRate.isOnCallShiftsEnabled
              )
            }
          />
        </ValueCell>
      </StyledGrid>
      <ButtonContainer>
        <RotaButton onClick={handleSave} disabled={isSaving || !hasChanges}>
          Save Changes
        </RotaButton>
      </ButtonContainer>
    </ContentWrapper>
  );
};

export default RoleRateDetails;
