import { gql, useQuery } from "@apollo/client";

export const GET_PO_NUMBERS = gql`
  query getPurchaseOrderNumbers($accountId: ID!) {
    account {
      targetAccount(accountId: $accountId) {
        purchaseOrderNumbers {
          id
          purchaseOrderNumber
          venueId
          venue {
            id
            name
          }
          subvenueId
          subvenue {
            id
            name
          }
          roleRateId
          role {
            id
            name
          }
          startDate
          endDate
          status
        }
      }
    }
  }
`;

export const useGetPurchaseOrderNumbers = accountId => {
  const { data, loading, error, refetch } = useQuery(GET_PO_NUMBERS, {
    variables: { accountId },
    fetchPolicy: "cache-and-network"
  });

  return {
    purchaseOrderNumbers:
      data?.account?.targetAccount?.purchaseOrderNumbers ?? [],
    loading,
    error,
    refetch
  };
};
