import moment from "moment-timezone";
import React, { useState, useEffect, useContext } from "react";
import { useDrop } from "react-dnd";

import { POOL_TYPES } from "@teamrota/rota-common";
import { Role } from "@teamrota/authlib";

import {
  AVAILABILITY_TYPES,
  DND_ITEM_TYPES,
  MEMBER_STATES,
  SHIFT_TYPES
} from "~/src/consts";

import useAuth from "~/src/auth/hooks/use-auth";
import { getFulfilmentSnack } from "~/src/utils/stream";
import { toDateRange } from "~/src/utils/date-range";

import { FocusShiftContext } from "~/src/containers/provide-schedule/FocusShift";
import BookingReplacementModal from "~/src/components/BookingReplacementModal";
import MemberAddWarningModal from "~/src/components/StaffZonesPanel/components/MemberAddWarningModal";

import { useReplaceCancelledBooking } from "~/src/components/BookingReplacementModal/useReplaceCancelledBooking.js";

import AvailableMemberBadge from "../../../AvailableMemberBadge";
import GridCard from "../../../GridCard/grid-card";
import BusyGridCard from "../../../BusyGridCard/busy-grid-card";
import UnavailableGridCard from "../../../UnavailableGridCard/unavailable-grid-card";

import {
  GridCardEmptyWrapper,
  PlusIcon,
  StyledShiftCell
} from "../../../GridCard/grid-card.styles";

import { useMemberDetails } from "../../graphql/use-member-details.";
import { useGetMember } from "../../graphql/use-get-member-state";
import { errorModal } from "~/src/utils/errors";

const isMemberInactive = member => {
  return (
    member?.state === MEMBER_STATES.PENDING ||
    member?.state === MEMBER_STATES.LOCKED
  );
};

const ShiftCell = ({
  cellIndex,
  dayIndex,
  scheduledMember,
  busyMember,
  startTime,
  isDailyView,
  isWeeklyView,
  isMemberAvailabilityEnabled,
  onPressGridCell,
  onPressShiftCell,
  onPressAvailabilityBadge,
  assignMemberToShift,
  setSnack
}) => {
  const { focusShift, clearFocusShift } = useContext(FocusShiftContext);

  const startOfDay = moment(
    scheduledMember?.scheduledDays?.[dayIndex]?.startOfDay
  );

  const endOfDay = moment(startOfDay).add(1, "days");

  const [isCellLoading, setIsCellLoading] = useState(false);
  const [lastDroppedShift, setLastDroppedShift] = useState(null);
  const [warning, setWarning] = useState(null);
  const [isShowCancellationModal, setIsShowCancellationModal] = useState(false);

  const { memberData, memberDataLoading } = useMemberDetails({
    lastDroppedShift,
    id: scheduledMember?.member.id,
    dates: toDateRange(lastDroppedShift ?? {})
  });

  const { replaceBooking } = useReplaceCancelledBooking();

  const { member } = useGetMember({
    memberId: scheduledMember?.member.id
  });

  const hasShiftUnfilledCancelledBookings =
    lastDroppedShift?.cancelledBookings?.totalResults > 0;

  const handleBookingReplacementConfirm = async ({ cancelledBookingId }) => {
    try {
      await replaceBooking({
        shiftId: lastDroppedShift.id,
        replacementBookings: [
          { cancelledBookingId, memberId: scheduledMember?.member.id }
        ]
      });
    } catch (e) {
      errorModal(e);
    }
    setIsShowCancellationModal(false);
    setLastDroppedShift(null);
  };

  const checkShiftCancellation = () => {
    if (hasShiftUnfilledCancelledBookings) {
      setIsShowCancellationModal(true);
    } else {
      assignMember();
    }
  };

  const assignMember = async () => {
    await assignMemberToShift({
      memberId: scheduledMember.member.id,
      shiftId: lastDroppedShift.id
    });

    setLastDroppedShift(null);
    setIsCellLoading(false);
    setIsShowCancellationModal(false);
    clearFocusShift();

    setSnack(getFulfilmentSnack(lastDroppedShift, scheduledMember));
  };

  useEffect(() => {
    if (memberData && !memberDataLoading) {
      if (
        memberData.isHitWorkingHoursLimit ||
        memberData.isConflicted ||
        memberData.isExcludedFromVenue ||
        memberData.isLocationConflict ||
        memberData.isUnavailable ||
        !memberData.isQualifiedWithRole ||
        !memberData.isQualifiedWithTags ||
        !memberData.isInVenueServiceArea ||
        memberData.relationship?.poolType === POOL_TYPES.BLACKLISTED ||
        isMemberInactive(memberData)
      ) {
        setWarning({
          member: memberData,
          callback: checkShiftCancellation
        });
      } else {
        checkShiftCancellation();
      }
    }
  }, [memberDataLoading]);

  const [{ draggedShift, isOver, canDrop }, drop] = useDrop(
    () => ({
      accept: DND_ITEM_TYPES.OPEN_SHIFT,
      drop: shift => {
        setIsCellLoading(true);
        setLastDroppedShift(shift);
      },
      canDrop: shift => {
        if (isWeeklyView) {
          const canDrop =
            moment(shift.startTime).isAfter(startOfDay) &&
            moment(shift.startTime).isBefore(endOfDay);

          return canDrop;
        } else {
          const canDrop =
            ((shift.type === SHIFT_TYPES.DAY || shift.type === null) &&
              isDayShiftCell) ||
            (shift.type === SHIFT_TYPES.NIGHT && isNightShiftCell);

          return canDrop;
        }
      },
      collect: monitor => ({
        isOver: monitor.isOver(),
        draggedShift: monitor.getItem(),
        canDrop: monitor.canDrop()
      })
    }),
    []
  );

  const canDropShiftOnCell = (draggedShift && canDrop) || !draggedShift;

  const getInitialShiftData = rowDayIndex => {
    const weekStartTime = moment(startTime)?.add(rowDayIndex, "d");
    onPressGridCell({
      startTime: weekStartTime,
      scheduledMember
    });
  };

  const handleCreateShift = () => {
    if (isMemberInactive(member)) setWarning({ member });
    else getInitialShiftData(dayIndex);
  };

  const auth = useAuth();

  const isCurrentDayBooking = !!scheduledMember?.scheduledDays?.[dayIndex]
    ?.bookings?.length;

  const isCurrentDayInFuture =
    moment() < moment(startTime)?.add(dayIndex + 1, "d");

  const isDayShiftCell = !isWeeklyView && cellIndex === 0;
  const isNightShiftCell = !isWeeklyView && cellIndex === 1;

  const memberBookings = scheduledMember?.scheduledDays?.[dayIndex]?.bookings
    .filter(booking => booking.state !== "APPLIED")
    .filter(({ shift }) =>
      isDayShiftCell
        ? shift.type === SHIFT_TYPES.DAY
        : isNightShiftCell
        ? shift.type === SHIFT_TYPES.NIGHT
        : true
    );

  const availability =
    isMemberAvailabilityEnabled &&
    scheduledMember?.scheduledDays?.[dayIndex]?.availability;

  const unavailability =
    scheduledMember?.scheduledDays?.[dayIndex]?.unavailability;

  const isNightShift = memberBookings?.[0]?.shift?.type === SHIFT_TYPES.NIGHT;

  const isShowShiftInColumn =
    isWeeklyView ||
    (isDayShiftCell && !isNightShift) ||
    (isNightShiftCell && isNightShift);

  const isAvailabilityNightShift =
    availability?.shiftType === AVAILABILITY_TYPES.NIGHT;

  const isAvailabilityAllDayShift =
    availability?.shiftType === AVAILABILITY_TYPES.ALL;

  const isSameShiftType =
    (!isNightShift && !isAvailabilityNightShift) ||
    (isNightShift && isAvailabilityNightShift);

  const isAvailabilityShiftCell =
    (isDayShiftCell && !isAvailabilityNightShift) ||
    (isNightShiftCell && isAvailabilityNightShift);

  const isShowAvailabilityInColumn =
    isWeeklyView || isAvailabilityAllDayShift || isAvailabilityShiftCell;

  const isHideCell =
    isDailyView &&
    focusShift !== null &&
    ((focusShift.type === "DAY" && !isDayShiftCell) ||
      (focusShift.type === "NIGHT" && !isNightShiftCell));

  const busy = busyMember[startOfDay.toISOString()] ?? [];
  const shouldShowBusy =
    !isCurrentDayBooking && !unavailability && busy.length > 0;

  const shouldShowCreateButton =
    auth.hasRole(Role.SHIFTS_CREATE) &&
    !isCurrentDayBooking &&
    !unavailability &&
    isCurrentDayInFuture &&
    !shouldShowBusy;

  return (
    <StyledShiftCell
      key={cellIndex}
      ref={drop}
      isGreyCell={!isCurrentDayInFuture || !canDropShiftOnCell}
      isHideCell={isHideCell}
      highlightCell={isOver && canDropShiftOnCell}
      isCellLoading={isCellLoading}
      isNightShiftCell={isNightShiftCell}
    >
      {isCurrentDayBooking && isShowShiftInColumn && (
        <GridCard
          key={cellIndex}
          booking={memberBookings}
          id={scheduledMember?.id + cellIndex}
          days={scheduledMember?.scheduledDays}
          onClick={onPressShiftCell}
          openCreateShift={() => getInitialShiftData(dayIndex)}
        />
      )}

      {!isCurrentDayBooking && unavailability && (
        <UnavailableGridCard
          unavailability={unavailability}
          onClick={() => getInitialShiftData(dayIndex)}
        />
      )}

      {shouldShowBusy && (
        <BusyGridCard
          busy={busy}
          onClick={() => getInitialShiftData(dayIndex)}
        />
      )}

      {!isMemberInactive(member) &&
        availability &&
        isShowAvailabilityInColumn && (
          <AvailableMemberBadge
            availability={availability}
            isDailyView={isDailyView}
            isWeeklyView={isWeeklyView}
            isNightShift={isNightShift && isNightShiftCell}
            isCurrentDayBooking={isCurrentDayBooking}
            isSameShiftType={isSameShiftType || isAvailabilityAllDayShift}
            onClick={onPressAvailabilityBadge}
          />
        )}

      {shouldShowCreateButton && (
        <GridCardEmptyWrapper
          lineHeight="12px"
          onClick={() => handleCreateShift()}
        >
          <PlusIcon name="PLUS" size="small" color="#C4C4C4" />
        </GridCardEmptyWrapper>
      )}

      <MemberAddWarningModal
        accountType={"provider"}
        roleRateId={lastDroppedShift?.roleRate?.id}
        dates={[toDateRange(lastDroppedShift ?? {})]}
        warning={warning}
        onClose={isAddingMember => {
          !isAddingMember && setIsCellLoading(false);
          setWarning(null);
        }}
      />

      {isShowCancellationModal && (
        <BookingReplacementModal
          onClose={assignMember}
          onConfirm={({ cancelledBookingId }) =>
            handleBookingReplacementConfirm({
              cancelledBookingId
            })
          }
          shiftId={lastDroppedShift?.id}
        />
      )}
    </StyledShiftCell>
  );
};

export default ShiftCell;
