import styled from "styled-components";
import { COLORS } from "~/src/styles/config.style";

export const StyledInfoBox = styled.div`
  margin-top: 3px;
  padding: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: #A3CAFF;
  border-radius: 6px;

  & a {
    color: white;
    font-weight: 800;
  }
}`;

export const ScrapeLink = styled.span`
  display: inline-block;
  position: relative;
  color: white;
  width: 155px;
  height: 32px;
  font-size: 14px;

  cursor: pointer;

  background-color: ${COLORS.MAIN_ORANGE};
  border-radius: 10px;

  padding: 5px 10px;
  margin: 5px 5px 0 0;

  &.selected {
    background-color: ${COLORS.WHITE};
    color: ${COLORS.MAIN_ORANGE};
  }

  &::after {
    content: "🔍";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;

export const ProviderLink = styled.div`
  color: white;
  white-space: nowrap;
  text-align: center;
  margin-top: 5px;
  cursor: pointer;
`;

export const SourceRefs = styled.div``;

export const ScrapeBox = styled.div`
  background-color: white;
  border: solid 1px white;
  margin-top: 5px;
`;

export const ImgOverflow = styled.div`
  overflow-x: auto;

  & img {
    width: 100%;
    cursor: zoom-in;
  }

  & img.expanded {
    width: auto;
    cursor: zoom-out;
  }
`;
