import { gql, useMutation } from "@apollo/client";

export const BULK_PURCHASE_ORDER_MUTATION = gql`
  mutation bulkMutatePurchaseOrderNumbers(
    $added: [AddPurchaseOrderNumberInput!]
    $updated: [UpdatePurchaseOrderNumberInput!]
    $deleted: [ID!]
  ) {
    bulkMutatePurchaseOrderNumbers(
      added: $added
      updated: $updated
      deleted: $deleted
    ) {
      added {
        id
        accountId
        venueId
        subvenueId
        roleRateId
        purchaseOrderNumber
        startDate
        endDate
        createdAt
        updatedAt
      }
      updated {
        id
        accountId
        venueId
        subvenueId
        roleRateId
        purchaseOrderNumber
        startDate
        endDate
        createdAt
        updatedAt
      }
      deleted {
        id
      }
    }
  }
`;

export const useBulkMutatePurchaseOrderNumbers = () => {
  const [bulkMutatePOs, { data, loading, error }] = useMutation(
    BULK_PURCHASE_ORDER_MUTATION,
    {
      fetchPolicy: "network-only",
      refetchQueries: ["getPurchaseOrderNumbers"]
    }
  );

  const bulkMutate = async ({ added = [], updated = [], deleted = [] }) => {
    try {
      const result = await bulkMutatePOs({
        variables: {
          added,
          updated,
          deleted
        }
      });

      return {
        success: true,
        data: result.data.bulkPurchaseOrderNumberMutation
      };
    } catch (err) {
      console.error("Bulk purchase order mutation error:", err);
      return {
        success: false,
        error: err
      };
    }
  };

  return {
    bulkMutate,
    data: data?.bulkPurchaseOrderNumberMutation,
    loading,
    error
  };
};
