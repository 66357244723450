import React from "react";
import {
  RotaButton,
  RotaDropdown,
  RotaInput,
  RotaStatusLabel
} from "@teamrota/rota-design";
import { COLORS } from "~/src/styles/config.style";
import {
  changeDateToDateString,
  formatDateToDateTime,
  getStatusType
} from "../utils";
import { DropdownWrapper, TableCell, TableRow } from "../po-numbers.styles";
import Icon from "~/src/components/icon";

const POTableRow = ({
  row,
  columnWidths,
  venueOptions,
  subvenueOptions,
  roleRateOptions,
  isBeingValidated,
  hasError,
  onUpdate,
  onDelete
}) => {
  return (
    <TableRow hasError={hasError}>
      <TableCell width={columnWidths.poNumber}>
        <RotaInput
          type="text"
          value={row.purchaseOrderNumber}
          maxLength="10"
          onChange={e =>
            onUpdate(row.id, "purchaseOrderNumber", e.target.value)
          }
          disabled={isBeingValidated}
        />
      </TableCell>
      <TableCell width={columnWidths.venue}>
        <DropdownWrapper>
          <RotaDropdown
            value={row.venueId}
            options={venueOptions}
            width="100%"
            onChange={option => onUpdate(row.id, "venueId", option)}
            disabled={isBeingValidated}
          />
        </DropdownWrapper>
      </TableCell>
      <TableCell width={columnWidths.subvenue}>
        <DropdownWrapper>
          <RotaDropdown
            value={row?.subvenueId || null}
            options={subvenueOptions}
            width="100%"
            onChange={option => onUpdate(row.id, "subvenueId", option)}
            disabled={isBeingValidated}
          />
        </DropdownWrapper>
      </TableCell>
      <TableCell width={columnWidths.role}>
        <DropdownWrapper>
          <RotaDropdown
            value={row.roleRateId}
            width="100%"
            options={roleRateOptions}
            onChange={option => onUpdate(row.id, "roleRateId", option)}
            disabled={isBeingValidated}
          />
        </DropdownWrapper>
      </TableCell>
      <TableCell width={columnWidths.startDate}>
        <RotaInput
          placeholder={"Start date"}
          type="date"
          value={changeDateToDateString(row.startDate)}
          onChange={e =>
            onUpdate(row.id, "startDate", formatDateToDateTime(e.target.value))
          }
          disabled={isBeingValidated}
        />
      </TableCell>
      <TableCell width={columnWidths.endDate}>
        <RotaInput
          placeholder={"End date"}
          type="date"
          value={changeDateToDateString(row.endDate)}
          onChange={e =>
            onUpdate(row.id, "endDate", formatDateToDateTime(e.target.value))
          }
          disabled={isBeingValidated}
        />
      </TableCell>
      <TableCell width={columnWidths.status}>
        <RotaStatusLabel
          type={getStatusType(row.status)}
          style={{ width: "100%", fontSize: "12px" }}
        >
          {row.status ?? "TBD"}
        </RotaStatusLabel>
      </TableCell>
      <TableCell width={columnWidths.actions}>
        <RotaButton
          style={{ width: "100%" }}
          size="small"
          color={COLORS.RED}
          variant="contained"
          onClick={() => onDelete(row.id)}
          disabled={isBeingValidated}
        >
          <Icon
            name={Icon.names.DELETE}
            color={Icon.colors.WHITE}
            size={Icon.sizes.XSMALL}
          />
        </RotaButton>
      </TableCell>
    </TableRow>
  );
};

export default POTableRow;
