import { get } from "lodash/fp";

export const CUSTOM_UNIFORM_ID = "mock-id-custom-uniform";

export const getValuesFromProps = props => ({
  timesheetId: get("timesheetMemberRow.timesheet.id", props),
  timesheetMemberRowId: get("timesheetMemberRow.id", props),
  venueId: get("timesheetMemberRow.venue.id", props),
  subvenue: get("timesheetMemberRow.subvenue.name", props),
  subvenueId: get("timesheetMemberRow.subvenue.id", props),
  role: get("timesheetMemberRow.role.name", props),
  roleId: get("timesheetMemberRow.role.id", props),
  startTime: get("timesheetMemberRow.scheduledStartTime", props),
  endTime: get("timesheetMemberRow.scheduledEndTime", props),
  breakMinutes: get("timesheetMemberRow.breakMinutes", props),
  purchaseOrderNumber: get("timesheetMemberRow.purchaseOrderNumber", props)
});
