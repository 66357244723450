export const COLUMN_WIDTHS = {
  poNumber: "12%",
  venue: "14%",
  subvenue: "14%",
  role: "14%",
  startDate: "16%",
  endDate: "16%",
  status: "9%",
  actions: "5%"
};
