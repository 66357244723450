import React from "react";
import {
  RotaTable,
  THead,
  TrHead,
  Th,
  TBody,
  Tr,
  Td,
  InnerCell,
  RotaCheckbox,
  RotaPlaceholder
} from "@teamrota/rota-design";
import { InnerCellTime } from "../style";

const Loader = () => {
  return (
    <RotaTable>
      <THead>
        <TrHead>
          <Th>
            <InnerCell>
              <RotaCheckbox isDisabled isChecked={false} onClick={() => {}} />
            </InnerCell>
          </Th>
          <Th />
          <Th style={{ textAlign: "left", minWidth: 266 }}>Name</Th>
          <Th style={{ minWidth: 180 }}>Feedback</Th>
          <Th>Role</Th>
          <Th>Scheduled hours</Th>
          <Th>In/Out</Th>
          <Th>Total hours</Th>
        </TrHead>
      </THead>
      <TBody>
        {Array.from({ length: 8 }).map((_, index) => {
          return (
            <Tr key={index}>
              <Td>
                <InnerCell>
                  <RotaCheckbox
                    isDisabled
                    isChecked={false}
                    onClick={() => {}}
                  />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder circle size={48} />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
              <Td>
                <InnerCellTime>
                  <RotaPlaceholder />
                </InnerCellTime>
              </Td>
              <Td>
                <InnerCell>
                  <RotaPlaceholder />
                </InnerCell>
              </Td>
            </Tr>
          );
        })}
      </TBody>
    </RotaTable>
  );
};

export default Loader;
