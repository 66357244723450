import React, { useEffect } from "react";
import moment from "moment-timezone";
// eslint-disable-next-line import/no-unassigned-import
import "moment-duration-format";

import { TableHeader, DateTime, OnCallTimeTable, ErrorMessage } from "./styles";
import TimeDateInput from "~/src/components/form-components/time-date-input";
import {
  getFormattedOnCallTimeDuration,
  isOnCallTimesValid,
  onOnCallTimeChange
} from "./helpers";
import { RotaCheckbox } from "@teamrota/rota-design";

const OnCallTime = ({
  selectedDates,
  selectedOnCallTimes,
  handleShiftUpdate,
  isOnCallTimesEnabled,
  isOnCallTimesOnNextDay
}) => {
  useEffect(() => {
    if (
      isOnCallTimesEnabled &&
      selectedOnCallTimes.startTime === null &&
      selectedOnCallTimes.endTime === null &&
      selectedDates?.[0]?.endTime
    ) {
      const shiftEnds = selectedDates
        .map(shift => moment(shift.endTime))
        .sort((a, b) => b.format("HH:mm").localeCompare(a.format("HH:mm")));
      const startTime = moment(shiftEnds[0]);
      const endTime = moment(shiftEnds[0]).add(8, "hours");

      const newOnCallTimes = {
        selectedOnCallTimes: {
          startTime: startTime,
          endTime: endTime
        }
      };
      handleShiftUpdate(newOnCallTimes);
    }
  }, [
    selectedDates,
    selectedOnCallTimes.startTime,
    selectedOnCallTimes.endTime,
    handleShiftUpdate,
    isOnCallTimesEnabled
  ]);

  const durationText = getFormattedOnCallTimeDuration(selectedOnCallTimes);

  return (
    <div>
      <RotaCheckbox
        label={"Add on-call times"}
        isChecked={isOnCallTimesEnabled}
        onClick={() => {
          handleShiftUpdate({
            isOnCallTimesEnabled: !isOnCallTimesEnabled
          });
        }}
      />
      {isOnCallTimesEnabled && (
        <div>
          <OnCallTimeTable cellPadding={0} cellSpacing={0}>
            <tbody>
              <tr>
                <td />
                <td>
                  <TableHeader>Start</TableHeader>
                </td>
                <td />
                <td>
                  <TableHeader>End</TableHeader>
                </td>
                <td>
                  <TableHeader>Duration</TableHeader>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <DateTime>
                    <TimeDateInput
                      isSmall
                      isBorderless
                      type="time"
                      onChange={val => {
                        onOnCallTimeChange(
                          handleShiftUpdate,
                          "startTime",
                          selectedOnCallTimes,
                          val,
                          selectedDates,
                          isOnCallTimesOnNextDay
                        );
                      }}
                      value={moment(selectedOnCallTimes.startTime).format(
                        "HH:mm"
                      )}
                    />
                  </DateTime>
                </td>
                <td>
                  <p style={{ margin: "0 5px" }}>-</p>
                </td>
                <td>
                  <DateTime>
                    <TimeDateInput
                      isSmall
                      isBorderless
                      type="time"
                      onChange={val => {
                        onOnCallTimeChange(
                          handleShiftUpdate,
                          "endTime",
                          selectedOnCallTimes,
                          val,
                          selectedDates,
                          isOnCallTimesOnNextDay
                        );
                      }}
                      value={moment(selectedOnCallTimes.endTime).format(
                        "HH:mm"
                      )}
                    />
                  </DateTime>
                </td>
                <td>{durationText}</td>
              </tr>
            </tbody>
            {!isOnCallTimesValid(
              selectedDates,
              selectedOnCallTimes,
              isOnCallTimesOnNextDay
            ) && (
              <ErrorMessage>
                On-call time can't conflict with the shift
              </ErrorMessage>
            )}
          </OnCallTimeTable>
        </div>
      )}
    </div>
  );
};

export default OnCallTime;
