import { gql, NetworkStatus, useQuery } from "@apollo/client";
import moment from "moment-timezone";
import { update } from "lodash/fp";

import { addAuthVars } from "@teamrota/authlib";

import client from "~/src/services/graphql";
import useAuth from "~/src/auth/hooks/use-auth";
import { useProfile } from "~/src/containers/profile-loader";

export const PAGE_SIZE = 25;

export const GET_SCHEDULES = addAuthVars(gql`
  query getSchedules(
    $startTime: Date!
    $endTime: Date!
    $roleIds: [ID]
    $sortBy: SortByType
    $searchTerm: String
    $homeVenueIds: [ID]
    $venueIds: [ID]
    $userIds: [ID]
    $memberTagIds: [ID]
    $offset: Int
    $limit: Int
    $partnerIds: [ID]
    $isShowCancelledShifts: Boolean
    $isShowCancelledBookings: Boolean
    $shiftTypes: [ShiftTypeEnumType]
    $shiftSources: [ShiftSourceEnumType]
    $availableOnDays: [String]
    $availableForType: ShiftTypeEnumType
    $availableForShift: ID
    $createdAfter: Date
    $forVenueId: ID
    $notExcludedFromVenueId: ID
    $excludeUnsuitable: Boolean
    $isSelfProvider: Boolean
  ) {
    account {
      id
      isMemberAvailabilityEnabled
      schedule(
        startTime: $startTime
        endTime: $endTime
        bookingStates: [ACCEPTED]
        roleIds: $roleIds
        sortBy: $sortBy
        searchTerm: $searchTerm
        homeVenueIds: $homeVenueIds
        venueIds: $venueIds
        userIds: $userIds
        memberTagIds: $memberTagIds
        offset: $offset
        limit: $limit
        partnerIds: $partnerIds
        isShowCancelledShifts: $isShowCancelledShifts
        isShowCancelledBookings: $isShowCancelledBookings
        shiftTypes: $shiftTypes
        shiftSources: $shiftSources
        availableOnDays: $availableOnDays
        availableForType: $availableForType
        availableForShift: $availableForShift
        createdAfter: $createdAfter
        forVenueId: $forVenueId
        notExcludedFromVenueId: $notExcludedFromVenueId
        excludeUnsuitable: $excludeUnsuitable
        isSelfProvider: $isSelfProvider
      ) {
        days
        draftShiftIds
        totalMembersCount
        totalScheduledBookingsCount
        totalUnassignedBookingsCount
        scheduledMembers {
          memberId
          member {
            id
            firstName
            lastName
            photo
            memberType
            venueId
            isLimitedHours
            limitedHours
            roles {
              roleId
              isDayRate
            }
          }
          scheduledDays {
            startOfDay
            bookings {
              id
              state
              shiftId
              shift {
                id
                startTime
                endTime
                name
                cancelledAt
                finalisedAt
                openedAt
                sourceAccountId
                shiftGroupId
                isDraft
                type
                isOnCallTimesEnabled
                sourceAccount {
                  accountName
                }
                stream {
                  isAutomate
                  sources {
                    id
                    numberRequested
                    numberFilled
                    url
                  }
                  account {
                    id
                    source
                    sourceName
                    name
                    url
                  }
                }
                venue {
                  id
                  address
                  name
                }
                ... on shift {
                  payRate
                  roleRate {
                    id
                    roleName
                    roleId

                    ... on roleRate {
                      payRate
                    }
                  }
                }
              }
            }
            availability {
              startsAt
              endsAt
              shiftType
            }
            unavailability {
              startsAt
              endsAt
            }
          }
          hoursCompleted
          shiftsWorked
        }
        unassignedShifts {
          date
          shifts {
            id
            type
            startTime
            numberRequested
            endTime
            finalisedAt
            openedAt
            isDraft
            name
            sourceAccountId
            targetAccountId
            shiftGroupId
            cancelledAt
            hasCancelledBookings
            stream {
              isAutomate
              sources {
                id
                numberRequested
                numberFilled
                url
              }
              account {
                id
                source
                sourceName
                name
                url
              }
            }
            sourceAccount {
              accountName
            }
            venue {
              id
              address
              name
            }
            ... on shift {
              payRate
              roleRate {
                id
                roleName
                roleId

                ... on roleRate {
                  payRate
                }
              }
            }
            bookings(stateIn: [ACCEPTED], offset: 0, limit: 100) {
              totalResults
            }
            cancelledBookings: bookings(
              stateEquals: CANCELLED
              offset: 0
              limit: 100
              hasReplacementBookingId: false
            ) {
              totalResults
            }
          }
        }
      }
    }
  }
`);

export const useSchedules = ({
  startTime,
  endTime,
  createdAfter,
  homeVenueIds,
  roleIds,
  sortBy,
  searchTerm,
  partnerIds,
  venueIds,
  userIds,
  memberTagIds,
  isShowCancelledShifts,
  isShowCancelledBookings,
  shiftTypes = [],
  shiftSources = [],
  availableOnDays = [],
  availableForType = null,
  availableForShift = null,
  forVenueId,
  notExcludedFromVenueId,
  excludeUnsuitable,
  isSelfProvider,
  skip
}) => {
  const auth = useAuth();
  const { user } = useProfile();

  const {
    data: scheduleData,
    refetch: refetchSchedules,
    fetchMore,
    loading,
    networkStatus
  } = useQuery(GET_SCHEDULES, {
    variables: auth.addVals(GET_SCHEDULES, {
      startTime: moment(startTime)
        .local()
        .format(),
      endTime: moment(endTime)
        .endOf("day")
        .local()
        .format(),
      createdAfter:
        createdAfter !== null
          ? moment(createdAfter)
              .local()
              .format()
          : null,
      homeVenueIds,
      roleIds,
      sortBy,
      searchTerm,
      partnerIds,
      venueIds,
      userIds,
      memberTagIds,
      isShowCancelledShifts,
      isShowCancelledBookings,
      shiftTypes,
      shiftSources,
      availableOnDays,
      availableForType,
      availableForShift,
      forVenueId,
      notExcludedFromVenueId,
      excludeUnsuitable,
      isSelfProvider
    }),
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    skip,
    onError: error => {
      throw error;
    }
  });

  const loadMoreSchedules = ({ onFinish }) => {
    fetchMore({
      variables: {
        offset: scheduleData.account.schedule.scheduledMembers.length,
        limit: PAGE_SIZE
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        onFinish?.();

        return update("account.schedule.scheduledMembers", current => [
          ...current,
          ...fetchMoreResult.account.schedule.scheduledMembers
        ])(previousResult);
      }
    });
  };

  const forceRefetchSchedules = () => {
    // clear cache
    client.cache.evict({ id: `account:${user.account.id}` });
    client.cache.gc();

    refetchSchedules();
  };

  return {
    schedulesLoading: loading || networkStatus === NetworkStatus.refetch,
    scheduleData,
    refetchSchedules,
    forceRefetchSchedules,
    loadMoreSchedules
  };
};
