import React from "react";
import { Currency } from "@teamrota/rota-common/dist/currency-support";
import { getFormatterMoney } from "~/src/utils/formatting";
import { convertHoursToTimeString } from "../utils";
import { TimesheetCostDetails } from "../use-timesheet-breakdown-cost-details";
import styled from "styled-components";
import { RotaDivider } from "@teamrota/rota-design";

const CostDetailsWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

const CostDetailsLine = styled.p`
  font-size: 16px;
  font-weight: 500;
  & span {
    font-weight: 700;
  }
`;

const SummaryLine = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: ${({ theme }) => theme.primary.main};
  & span {
    margin-left: 98px;
    color: ${({ theme }) => theme.text.primary};
  }
`;

const TimesheetCostDetailsSummary = ({
  isUncalculatedRole,
  timesheetCostDetails,
  currencyCode
}: {
  isUncalculatedRole: boolean;
  timesheetCostDetails: TimesheetCostDetails;
  currencyCode: Currency;
}) => {
  const formatterMoney = getFormatterMoney(currencyCode);

  const {
    totalRegularWorkedHours,
    chargeRate,
    totalCharge,
    totalBonusAmount,
    totalVat,
    totalWithVat,
    totalOverHours,
    totalOverHourAmount,
    overstayChargeRate,
    overtimeChargeRate
  } = timesheetCostDetails;

  if (isUncalculatedRole) {
    return (
      <CostDetailsWrapper>
        <CostDetailsLine>
          {`Total Working Hours = `}
          <span>{convertHoursToTimeString(totalRegularWorkedHours)}</span>
        </CostDetailsLine>
        <CostDetailsLine>
          <span>
            Pay for this shift will be calculated in the Payroll download
          </span>
        </CostDetailsLine>
      </CostDetailsWrapper>
    );
  }

  return (
    <CostDetailsWrapper>
      <CostDetailsLine>
        {`${convertHoursToTimeString(
          totalRegularWorkedHours
        )} x ${formatterMoney.format(chargeRate / 100)} = `}
        <span>{formatterMoney.format(totalCharge / 100)}</span>
      </CostDetailsLine>
      <CostDetailsLine>
        {`${convertHoursToTimeString(totalOverHours)} x ${formatterMoney.format(
          (overstayChargeRate || overtimeChargeRate) / 100
        )} = `}
        <span>{formatterMoney.format(totalOverHourAmount / 100)}</span>
      </CostDetailsLine>
      <CostDetailsLine>
        {`Total Bonus = `}
        <span>{formatterMoney.format(totalBonusAmount / 100)}</span>
      </CostDetailsLine>
      <CostDetailsLine>
        {`VAT (20%) = `}
        <span>{formatterMoney.format(totalVat / 100)}</span>
      </CostDetailsLine>
      <RotaDivider />
      <SummaryLine>
        {`Total: `}
        <span>{formatterMoney.format(totalWithVat / 100)}</span>
      </SummaryLine>
    </CostDetailsWrapper>
  );
};

export default TimesheetCostDetailsSummary;
