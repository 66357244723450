import { gql, useQuery } from "@apollo/client";

export const GET_VENUES = gql`
  query getVenues($partnerId: ID) {
    account {
      partnerVenues(partnerId: $partnerId) {
        name
        id
        subvenues {
          name
          id
        }
      }
    }
  }
`;

export const useGetVenues = partnerId => {
  const { data, loading, error, refetch } = useQuery(GET_VENUES, {
    variables: { partnerId },
    fetchPolicy: "cache-and-network"
  });

  return {
    venues: data?.account?.partnerVenues ?? [],
    loading,
    error,
    refetch
  };
};
