import React from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import head from "lodash/fp/head";
import map from "lodash/fp/map";
import getOr from "lodash/fp/getOr";

import {
  RotaButton,
  RotaSwitch,
  iconNew,
  RotaCheckbox
} from "@teamrota/rota-design";

import { SHIFT_TYPES } from "~/src/consts";
import { useUpdateTime } from "~/src/utils/useUpdateTime";
import { errorModal } from "~/src/utils/errors";

import Calendar from "~/src/components/calendar";
import FormStepSegment from "~/src/components/form-step-segment";

import { AdditionalNoteText } from "~/src/containers/scheduler/ShiftCreateModal/FormCalendar/form-calendar.styles";

import TimeDateInput from "~/src/components/form-components/time-date-input";
import { isSleepTimesValid } from "~/src/components/SleepTime/helpers";
import SleepTime from "~/src/components/SleepTime";
import { useHasBreaksConfig } from "~/src/graphql/queries/use-has-breaks-config/use-has-breaks-config";

import { useValidateCalendar } from "../shared";
import { DATE_FORMAT } from "../selectors";

import {
  Actions,
  Header,
  HeaderTitle,
  EditButton,
  DayOrNightShiftWrapper
} from "../styles";

import { mapStateToProps, mapDispatchToProps, getFormTitle } from "./selectors";

import {
  LinkedShiftCheckbox,
  DateTitle,
  DateTime,
  Group,
  Item,
  ScrollerContainer,
  Scroller,
  TableHeader,
  BreakInputWrapper,
  BreakWrapper,
  BreakMinText
} from "./form-calendar.styles";
import OnCallTime from "~/src/components/OnCallTime";
import { isOnCallTimesValid } from "~/src/components/OnCallTime/helpers";

const { Moon, Sun } = iconNew;

const FormCalendar = props => {
  const {
    isOpen,
    stepNumber,
    hasVisited,
    goToSelf,
    selectedDates,
    setEndRangeDate,
    toggleDate,
    shift,
    hasCompletedAllSteps,
    goToNext,
    handleShiftUpdate,
    isAccountSleepTimeEnabled
  } = props;

  const {
    selectedSleepTimes,
    isSleepTimesEnabled,
    selectedOnCallTimes,
    isOnCallTimesEnabled,
    isOnCallShiftsEnabledForRole,
    isOnCallTimesOnNextDay
  } = shift;

  const isCalendarValid = useValidateCalendar(props);
  const onUpdateTime = useUpdateTime(props);

  const onChangeBreakMinutes = (event, key) => {
    const newTimes = { ...shift.times };

    newTimes[key] = {
      ...newTimes[key],
      breakMinutes: parseInt(event?.target?.value) || 0
    };

    handleShiftUpdate({
      times: newTimes
    });
  };

  const onToggleShiftType = e => {
    const type = e.target.checked ? SHIFT_TYPES.DAY : SHIFT_TYPES.NIGHT;
    handleShiftUpdate({
      type
    });
  };

  const hasBreaksConfig = useHasBreaksConfig(shift);

  const isSleepTimesError =
    isSleepTimesEnabled &&
    !isSleepTimesValid(selectedDates, selectedSleepTimes);

  const isOnCallTimesError =
    isOnCallTimesEnabled &&
    !isOnCallTimesValid(
      selectedDates,
      selectedOnCallTimes,
      isOnCallTimesOnNextDay
    );

  return (
    <FormStepSegment
      isOpen={isOpen}
      stepNumber={stepNumber}
      title={() => (
        <Header>
          <HeaderTitle>
            {hasVisited && isCalendarValid
              ? getFormTitle(props)
              : "Select your range"}
          </HeaderTitle>
          {hasVisited && !isOpen && (
            <EditButton onClick={goToSelf}>
              {isCalendarValid ? "Edit" : "Missing Info!"}
            </EditButton>
          )}
        </Header>
      )}
    >
      {() => (
        <div>
          <Group>
            <Item width="55%">
              <Calendar
                startDate={getOr(moment(), "startTime", head(selectedDates))}
                selectedDates={map("startTime")(selectedDates)}
                onDateClick={toggleDate}
                onEndDateClick={setEndRangeDate}
              />
            </Item>
            {selectedDates.length > 0 && (
              <Item width="45%">
                <ScrollerContainer>
                  <DayOrNightShiftWrapper>
                    <span>Day or night shift</span>
                    <RotaSwitch
                      iconOn={<Sun />}
                      iconOff={<Moon />}
                      checked={shift.type === SHIFT_TYPES.DAY}
                      onChange={onToggleShiftType}
                    />
                  </DayOrNightShiftWrapper>

                  {isAccountSleepTimeEnabled && (
                    <SleepTime
                      selectedDates={selectedDates}
                      selectedSleepTimes={selectedSleepTimes}
                      handleShiftUpdate={handleShiftUpdate}
                      isSleepTimesEnabled={isSleepTimesEnabled}
                    />
                  )}

                  {isOnCallShiftsEnabledForRole && (
                    <OnCallTime
                      selectedDates={selectedDates}
                      selectedOnCallTimes={selectedOnCallTimes}
                      handleShiftUpdate={handleShiftUpdate}
                      isOnCallTimesEnabled={isOnCallTimesEnabled}
                      isOnCallTimesOnNextDay={isOnCallTimesOnNextDay}
                    />
                  )}

                  <Scroller>
                    <table cellPadding={0} cellSpacing={0}>
                      <tbody>
                        <tr>
                          <td />
                          <td>
                            <TableHeader>Start</TableHeader>
                          </td>
                          <td />
                          <td>
                            <TableHeader>End</TableHeader>
                          </td>
                          {!hasBreaksConfig && (
                            <td>
                              <TableHeader>Break</TableHeader>
                            </td>
                          )}
                        </tr>
                        {selectedDates.map((date, index) => (
                          <tr key={index}>
                            <td>
                              <DateTitle>
                                {moment(date.startTime).isSame(
                                  moment(date.endTime),
                                  "day"
                                )
                                  ? moment(date.startTime).format("Do MMM")
                                  : `${moment(date.startTime).format(
                                      "Do"
                                    )} - ${moment(date.endTime).format(
                                      "Do MMM"
                                    )}`}
                                :
                              </DateTitle>
                            </td>
                            <td>
                              <DateTime>
                                <TimeDateInput
                                  isSmall
                                  isBorderless
                                  type="time"
                                  onChange={val =>
                                    onUpdateTime("startTime", date, val)
                                  }
                                  value={moment(date.startTime).format("HH:mm")}
                                />
                              </DateTime>
                            </td>
                            <td>
                              <p>-</p>
                            </td>
                            <td>
                              <DateTime style={{ marginLeft: "5px" }}>
                                <TimeDateInput
                                  isSmall
                                  isBorderless
                                  type="time"
                                  onChange={val =>
                                    onUpdateTime("endTime", date, val)
                                  }
                                  value={moment(date.endTime).format("HH:mm")}
                                />
                              </DateTime>
                            </td>
                            {!hasBreaksConfig && (
                              <td>
                                <BreakWrapper>
                                  <BreakInputWrapper>
                                    <TimeDateInput
                                      isSmall
                                      isBorderless
                                      type="text"
                                      onChange={event =>
                                        onChangeBreakMinutes(
                                          event,
                                          moment(date.startTime).format(
                                            DATE_FORMAT
                                          )
                                        )
                                      }
                                      value={date.breakMinutes}
                                    />
                                  </BreakInputWrapper>
                                  <BreakMinText> min</BreakMinText>
                                </BreakWrapper>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <AdditionalNoteText>
                      Note: Start time must always come before end time.
                      {hasBreaksConfig && (
                        <> Break will be set automatically for this shift.</>
                      )}
                    </AdditionalNoteText>
                  </Scroller>
                </ScrollerContainer>
              </Item>
            )}
          </Group>

          <Actions justify="center">
            {selectedDates.length > 1 && (
              <LinkedShiftCheckbox>
                <RotaCheckbox
                  isChecked={shift.isLinkedShifts}
                  onClick={() => {
                    handleShiftUpdate({
                      isLinkedShifts: !shift.isLinkedShifts
                    });
                  }}
                  label="Post as linked shifts?"
                />
              </LinkedShiftCheckbox>
            )}
            {isCalendarValid && (
              <RotaButton
                onClick={() => {
                  if (shift.isLinkedShifts) {
                    let sortedArr = [
                      ...selectedDates.sort((a, b) => {
                        if (a.startTime < b.startTime) {
                          return -1;
                        }
                        if (a.startTime > b.startTime) {
                          return 1;
                        }
                        return 0;
                      })
                    ];

                    let hasOverlappingShifts = false;
                    while (sortedArr.length >= 2) {
                      let first = sortedArr.shift();
                      let second = sortedArr.shift();

                      if (moment(first.endTime) > moment(second.startTime)) {
                        hasOverlappingShifts = true;
                      }

                      if (sortedArr.length === 1) {
                        let last = sortedArr.shift();
                        if (moment(second.endTime) > moment(last.startTime)) {
                          hasOverlappingShifts = true;
                        }
                      }
                    }

                    if (hasOverlappingShifts) {
                      errorModal(
                        "A linked shift can not have overlapping times"
                      );
                    } else {
                      goToNext();
                    }
                  } else {
                    goToNext();
                  }
                }}
                disabled={isSleepTimesError || isOnCallTimesError}
              >
                {hasCompletedAllSteps ? "Done" : "Next"}
              </RotaButton>
            )}
          </Actions>
        </div>
      )}
    </FormStepSegment>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(FormCalendar);
