import moment from "moment-timezone";
import { PO_NUMBER_STATUSES } from "~/src/consts";

export const changeDateToDateString = date =>
  moment(date)
    .format("YYYY-MM-DD")
    .toString();

export const formatDateToDateTime = dateString => {
  if (!dateString) return null;

  try {
    const date = moment(dateString);

    // Check if date is valid
    if (!date.isValid()) {
      return null;
    }
    date.startOf("day");
    date.millisecond(999);
    return date.toISOString();
  } catch (e) {
    console.error("Error formatting date:", e);
    return null;
  }
};

export const rowHasRequiredFields = row => {
  return Boolean(
    row.purchaseOrderNumber?.trim() &&
      row.venueId &&
      row.roleRateId &&
      row.startDate &&
      row.endDate
  );
};

export const getSubvenueOptions = (venueId, venues) => {
  if (!venueId) return [];

  const selectedVenue = venues.find(
    venue => String(venue.id) === String(venueId)
  );

  // Create a default "Select Subvenue" option with null value
  const defaultOption = { value: null, label: "Select Subvenue" };

  const subvenueOptions =
    selectedVenue?.subvenues?.map(sub => ({
      value: sub.id,
      label: sub.name
    })) || [];

  return [defaultOption, ...subvenueOptions];
};
export const getStatusType = status => {
  switch (status) {
    case PO_NUMBER_STATUSES.ACTIVE:
      return "success";
    case PO_NUMBER_STATUSES.EXPIRED:
      return "error";
    default:
      return "warning";
  }
};

export const validatePORow = row => {
  const errors = {};

  if (!row.purchaseOrderNumber?.trim())
    errors.purchaseOrderNumber = "PO Number is required.";

  if (!row.venueId && !row.roleRateId)
    errors.venueId = "Either a venue or a role must be specified.";

  if (!row.startDate) errors.startDate = "Start date is required.";

  if (!row.endDate) errors.endDate = "End date is required.";

  return errors;
};

export const createNewPORow = () => {
  return {
    id: Date.now().toString(),
    purchaseOrderNumber: "",
    venueId: null,
    subvenueId: null,
    roleRateId: null,
    startDate: null,
    endDate: null
  };
};

export const prepareApiChanges = (changes, targetAccountId, formatDateFn) => {
  return {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    added: changes.added.map(({ id: _id, ...rest }) => ({
      ...rest,
      accountId: targetAccountId,
      startDate: formatDateFn(rest.startDate),
      endDate: formatDateFn(rest.endDate)
    })),
    updated: changes.updated.map(row => ({
      id: row.id,
      roleRateId: row.roleRateId,
      subvenueId: row.subvenueId,
      venueId: row.venueId,
      purchaseOrderNumber: row.purchaseOrderNumber,
      startDate: formatDateFn(row.startDate),
      endDate: formatDateFn(row.endDate)
    })),
    deleted: changes.deleted.map(({ id }) => id)
  };
};

export const updateRowValidationErrors = (
  prevErrors,
  id,
  field,
  value,
  row
) => {
  const rowErrors = { ...(prevErrors[id] || {}) };

  // Check if this field should have a validation error
  if (field === "purchaseOrderNumber") {
    if (!value?.trim()) {
      rowErrors.purchaseOrderNumber = "PO Number is required";
    } else {
      delete rowErrors.purchaseOrderNumber;
    }
  }

  // Handle venue OR role validation
  if (field === "venueId" || field === "roleRateId") {
    if (!row.venueId && !row.roleRateId) {
      rowErrors.venueOrRole = "Either Venue or Role is required";
    } else {
      delete rowErrors.venueOrRole;
    }
  }

  if (field === "startDate") {
    if (!value) {
      rowErrors.startDate = "Start date is required";
    } else {
      delete rowErrors.startDate;
    }
  }

  if (field === "endDate") {
    if (!value) {
      rowErrors.endDate = "End date is required";
    } else {
      delete rowErrors.endDate;
    }
  }

  // If no errors for this row, remove it from the object
  if (Object.keys(rowErrors).length === 0) {
    const newErrors = { ...prevErrors };
    delete newErrors[id];
    return newErrors;
  }

  return { ...prevErrors, [id]: rowErrors };
};

export const handleRowChangeTracking = (
  id,
  updatedData,
  changes,
  setChanges,
  isNewRowFn
) => {
  if (!isNewRowFn(id)) {
    const currentRow = updatedData.find(item => item.id === id);
    const existingUpdateIndex = changes.updated.findIndex(
      item => item.id === id
    );

    if (existingUpdateIndex >= 0) {
      setChanges(prev => {
        const newUpdated = [...prev.updated];
        newUpdated[existingUpdateIndex] = currentRow;
        return { ...prev, updated: newUpdated };
      });
    } else {
      setChanges(prev => ({
        ...prev,
        updated: [...prev.updated, currentRow]
      }));
    }
  } else {
    setChanges(prev => {
      const newAdded = prev.added.map(item =>
        item.id === id ? updatedData.find(row => row.id === id) : item
      );
      return { ...prev, added: newAdded };
    });
  }
};

export const clearRowValidationState = (
  id,
  setValidationErrors,
  debouncedValidations,
  setDebouncedValidations,
  setRowsBeingValidated
) => {
  // Clear validation errors
  setValidationErrors(prev => {
    const newErrors = { ...prev };
    delete newErrors[id];
    return newErrors;
  });

  // Clear pending validations
  if (debouncedValidations[id]) {
    clearTimeout(debouncedValidations[id]);
    setDebouncedValidations(prev => {
      const updated = { ...prev };
      delete updated[id];
      return updated;
    });
  }

  // Clear from validation tracking
  setRowsBeingValidated(prev => {
    const updated = { ...prev };
    delete updated[id];
    return updated;
  });
};
