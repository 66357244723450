import React, { useState } from "react";
import get from "lodash/fp/get";
import { flow } from "lodash";
import { ZonedDate } from "@teamrota/rota-common";

import {
  BOOKING_STATES,
  BOOKING_STATES_LABELS,
  UPDATABLE_BOOKING_STATES
} from "~/src/consts";
import updateBooking from "~/src/graphql/mutations/update-booking/update-booking.decorator";
import { errorModal } from "~/src/utils/errors";
import asyncConfirm from "~/src/utils/async-confirm";
import client from "~/src/services/graphql";
import AsyncCollapse from "~/src/components/async-collapse";
import MemberAddWarningModal from "~/src/components/StaffZonesPanel/components/MemberAddWarningModal";
import ViewEditBooking from "~/src/containers/modals/view-edit-booking";
import { GET_MEMBER_LAZY } from "~/src/components/StaffZonesPanel/graphql/queries/get-member-lazy";
import BookingReplacementModal from "~/src/components/BookingReplacementModal";
import { useReplaceCancelledBooking } from "~/src/components/BookingReplacementModal/useReplaceCancelledBooking.js";

import FutureShift from "./future-shift";
import PastShift from "./past-shift";
import getProvideBookings from "./graphql/get-bookings";
import { ListContainer } from "./booking-list.styles";

const BookingList = ({
  shift,
  bookings,
  setTabIndex,
  shiftItem,
  isLoadingProvideBookings,
  fetchMore,
  refetch,
  refetchBookings,
  updateBooking
}) => {
  const [warning, setWarning] = useState(null);
  const [selectedBookingId, setSelectedBookingId] = useState(undefined);
  const [isLoadingMemberStateChange, setIsLoadingMemberStateChange] = useState(
    false
  );
  const [isShowCancellationModal, setIsShowCancellationModal] = useState(false);

  const [selectedMemberId, setSelectedMemberId] = useState(null);
  const [newBookingData, setNewBookingData] = useState({
    booking: null,
    newState: null,
    bookingsLimit: null
  });

  const { replaceBooking } = useReplaceCancelledBooking();

  const dates = [{ startTime: shift.startTime, endTime: shift.endTime }];

  const handleUpdateBooking = async (variables, memberId, bookingsLimit) => {
    setIsLoadingMemberStateChange(memberId);
    try {
      await updateBooking({ variables });
      await refetchBookings({
        bookingsLimit
      });
      await refetch();
    } catch (error) {
      errorModal(error.message);
    }
    setIsLoadingMemberStateChange(false);
  };

  const handleStateChange = async (booking, newState, bookingsLimit) => {
    const message =
      "Are you sure you want to update this booking to" +
      ` '${BOOKING_STATES_LABELS[newState]}'`;

    setSelectedMemberId(booking.member.id);

    if (
      await asyncConfirm(message, {
        shouldHideSubText: true,
        shouldAddPadding: true,
        confirmButtonText: "Yes",
        isConfirmButtonGreen: true
      })
    ) {
      if (
        shift?.cancelledBookings?.totalResults > 0 &&
        newState === BOOKING_STATES.ACCEPTED
      ) {
        setIsShowCancellationModal(true);
        setNewBookingData({ booking, newState, bookingsLimit });
      } else {
        await handleConfirmStateChange(booking, newState, bookingsLimit);
        setSelectedMemberId(null);
      }
    }
  };

  const handleConfirmStateChange = async (booking, newState, bookingsLimit) => {
    try {
      const {
        data: { account }
      } = await client.query({
        query: GET_MEMBER_LAZY,
        variables: {
          shiftId: shift.id,
          id: booking.member.id,
          dates,
          shiftType: shift.type,
          roleRateId: shift.roleRate.id
        },
        fetchPolicy: "network-only"
      });

      const updateVariables = {
        id: booking.id,
        state: newState,
        accountType: "provider"
      };

      if (
        account?.isOverLimitWorkAllowed &&
        account?.member?.isHitWorkingHoursLimit &&
        newState === BOOKING_STATES.ACCEPTED
      ) {
        setWarning({
          member: account?.member,
          callback: async () => {
            await handleUpdateBooking(
              updateVariables,
              booking.member.id,
              bookingsLimit
            );
          }
        });
      } else {
        await handleUpdateBooking(
          updateVariables,
          booking.member.id,
          bookingsLimit
        );
      }
    } catch (e) {
      errorModal(e.message);
    }
  };

  const handleBookingReplacementConfirm = async ({ cancelledBookingId }) => {
    try {
      cancelledBookingId
        ? await replaceBooking({
            shiftId: shift.id,
            replacementBookings: [
              { cancelledBookingId, memberId: selectedMemberId }
            ]
          })
        : await handleConfirmStateChange(
            newBookingData.booking,
            newBookingData.newState,
            newBookingData.bookingsLimit
          );
    } catch (e) {
      errorModal(e);
    }
    setIsShowCancellationModal(false);
    setSelectedMemberId(null);
    setNewBookingData({
      booking: null,
      newState: null,
      bookingsLimit: null
    });
  };

  const selectOptions = Object.keys(UPDATABLE_BOOKING_STATES).map(state => ({
    label: UPDATABLE_BOOKING_STATES[state],
    value: state
  }));

  return (
    <AsyncCollapse isExpanded={get("isOpen", shiftItem)}>
      <ViewEditBooking
        isOpen={!!selectedBookingId}
        onClose={() => setSelectedBookingId(null)}
        bookingId={selectedBookingId}
      />
      <ListContainer>
        {new ZonedDate(shift.endTime) > new ZonedDate() ? (
          <FutureShift
            selectOptions={selectOptions}
            bookings={bookings}
            handleStateChange={handleStateChange}
            setTabIndex={setTabIndex}
            shift={shift}
            shiftItem={shiftItem}
            isLoadingProvideBookings={isLoadingProvideBookings}
            fetchMore={fetchMore}
            onViewBooking={bookingId => setSelectedBookingId(bookingId)}
            isLoadingMemberStateChange={isLoadingMemberStateChange}
          />
        ) : (
          <PastShift
            bookings={bookings}
            shift={shift}
            shiftItem={shiftItem}
            fetchMore={fetchMore}
            isLoading={isLoadingProvideBookings}
            setTabIndex={setTabIndex}
            onViewBooking={bookingId => setSelectedBookingId(bookingId)}
          />
        )}
      </ListContainer>
      {warning && (
        <MemberAddWarningModal
          accountType="provider"
          roleRateId={shift.roleRate.id}
          dates={dates}
          warning={warning}
          onClose={() => setWarning(null)}
        />
      )}

      {isShowCancellationModal && (
        <BookingReplacementModal
          onClose={() =>
            handleBookingReplacementConfirm({
              cancelledBookingId: null
            })
          }
          onConfirm={({ cancelledBookingId }) =>
            handleBookingReplacementConfirm({
              cancelledBookingId
            })
          }
          shiftId={shift.id}
        />
      )}
    </AsyncCollapse>
  );
};

export default flow(getProvideBookings, updateBooking)(BookingList);
