import { useState, useEffect, useCallback } from "react";
import asyncConfirm from "~/src/utils/async-confirm";
import { useValidatePurchaseOrderNumber } from "../graphql/validate-po-number";
import { useToast } from "@teamrota/rota-design";
import {
  formatDateToDateTime,
  validatePORow,
  createNewPORow,
  prepareApiChanges,
  updateRowValidationErrors,
  handleRowChangeTracking,
  clearRowValidationState
} from "../utils";

export const usePOTable = (initialPONumbers, targetAccountId) => {
  // State definitions
  const [originalData, setOriginalData] = useState(initialPONumbers);
  const [poData, setPOData] = useState(initialPONumbers);
  const [hasChanges, setHasChanges] = useState(false);
  const [changes, setChanges] = useState({
    added: [],
    updated: [],
    deleted: []
  });
  const [validationErrors, setValidationErrors] = useState({});
  const [isValidating, setIsValidating] = useState(false);
  const [rowsBeingValidated, setRowsBeingValidated] = useState({});
  const [debouncedValidations, setDebouncedValidations] = useState({});

  // Initiate hooks
  const { validate: validatePONumber } = useValidatePurchaseOrderNumber();
  const { showToast } = useToast();

  // Reset data when initialPONumbers changes
  useEffect(() => {
    setOriginalData(initialPONumbers);
    setPOData(initialPONumbers);
    setHasChanges(false);
    setChanges({ added: [], updated: [], deleted: [] });
  }, [initialPONumbers]);

  // Helper functions
  const isNewRow = useCallback(
    id =>
      !originalData.some(item => item.id === id) &&
      changes.added.some(item => item.id === id),
    [originalData, changes.added]
  );

  const rowHasErrors = useCallback(
    rowId =>
      validationErrors[rowId] &&
      Object.keys(validationErrors[rowId]).length > 0,
    [validationErrors]
  );

  const hasValidationErrors = useCallback(
    () =>
      Object.values(validationErrors).some(
        errors => errors && Object.keys(errors).length > 0
      ),
    [validationErrors]
  );

  const canValidateRow = useCallback(
    row =>
      row.purchaseOrderNumber?.trim() &&
      (row.venueId || row.roleRateId) &&
      row.startDate &&
      row.endDate,
    []
  );

  // Validate a single row
  const validateRow = async row => {
    if (!canValidateRow(row)) {
      const errors = validatePORow(row);
      setValidationErrors(prev => ({ ...prev, [row.id]: errors }));
      return Object.keys(errors).length === 0;
    }

    setRowsBeingValidated(prev => ({ ...prev, [row.id]: true }));

    try {
      const validationPayload = {
        ...(isNewRow(row.id) ? {} : { id: row.id }),
        accountId: targetAccountId,
        venueId: row.venueId,
        subvenueId: row.subvenueId,
        roleRateId: row.roleRateId,
        purchaseOrderNumber: row.purchaseOrderNumber,
        startDate: formatDateToDateTime(row.startDate),
        endDate: formatDateToDateTime(row.endDate)
      };

      const isValid = await validatePONumber(validationPayload);

      setValidationErrors(prev => {
        const updated = { ...prev };
        delete updated[row.id];
        return updated;
      });
      return isValid;
    } catch (error) {
      const errorMessage =
        error?.message ||
        "This combination of venue, role, and dates is invalid or conflicts with existing PO numbers.";

      showToast(errorMessage, { severity: "error" });
      setValidationErrors(prev => ({
        ...prev,
        [row.id]: {
          general: errorMessage
        }
      }));
      return false;
    } finally {
      setRowsBeingValidated(prev => {
        const updated = { ...prev };
        delete updated[row.id];
        return updated;
      });
    }
  };

  // Row operations
  const handleAddRow = () => {
    const newRow = createNewPORow();

    // Add validation errors for required fields
    setValidationErrors(prev => ({
      ...prev,
      [newRow.id]: {
        purchaseOrderNumber: "PO Number is required",
        venueOrRole: "Either Venue or Role is required",
        startDate: "Start date is required",
        endDate: "End date is required"
      }
    }));

    const newData = [...poData, newRow];
    setPOData(newData);
    setChanges(prev => ({ ...prev, added: [...prev.added, newRow] }));
    setHasChanges(true);

    return newData;
  };

  const handleRowUpdate = (id, field, value) => {
    // Update data
    const updatedData = poData.map(item => {
      if (item.id === id) {
        const updates = { [field]: value };
        if (field === "venueId") updates.subvenueId = null;
        return { ...item, ...updates };
      }
      return item;
    });

    setPOData(updatedData);

    // Track changes
    handleRowChangeTracking(id, updatedData, changes, setChanges, isNewRow);
    setHasChanges(true);

    // Get updated row
    const row = updatedData.find(r => r.id === id);

    // Update validation errors
    setValidationErrors(prev =>
      updateRowValidationErrors(prev, id, field, value, row)
    );

    // Debounce validation
    if (debouncedValidations[id]) clearTimeout(debouncedValidations[id]);

    if (row && canValidateRow(row)) {
      const timeoutId = setTimeout(() => {
        validateRow(row);
        setDebouncedValidations(prev => {
          const updated = { ...prev };
          delete updated[id];
          return updated;
        });
      }, 800);

      setDebouncedValidations(prev => ({ ...prev, [id]: timeoutId }));
    }

    return updatedData;
  };

  const handleDeleteRow = id => {
    const updatedData = poData.filter(item => item.id !== id);
    setPOData(updatedData);

    // Clear validation state
    clearRowValidationState(
      id,
      setValidationErrors,
      debouncedValidations,
      setDebouncedValidations,
      setRowsBeingValidated
    );

    // Update changes tracking
    if (isNewRow(id)) {
      setChanges(prev => ({
        ...prev,
        added: prev.added.filter(item => item.id !== id)
      }));
    } else {
      const rowToDelete = originalData.find(item => item.id === id);
      setChanges(prev => ({
        ...prev,
        deleted: [...prev.deleted, rowToDelete],
        updated: prev.updated.filter(item => item.id !== id)
      }));
    }

    setHasChanges(true);
    return updatedData;
  };

  const handleCancel = async () => {
    if (
      await asyncConfirm("Are you sure you want to cancel?", {
        subText:
          "You have unsaved changes to this purchase order number. All modifications will be lost.",
        confirmButtonText: "Yes",
        falseButtonText: "No"
      })
    ) {
      setPOData([...originalData]);
      setChanges({ added: [], updated: [], deleted: [] });
      setValidationErrors({});
      setHasChanges(false);
    }
  };

  const handleSave = async onSaveChanges => {
    setIsValidating(true);

    try {
      const rowsToValidate = [
        ...changes.added.map(row => row.id),
        ...changes.updated.map(row => row.id)
      ];

      if (rowsToValidate.length === 0 && changes.deleted.length > 0) {
        await submitChanges(onSaveChanges);
        return;
      }

      const changedRows = poData.filter(row => rowsToValidate.includes(row.id));
      const validationResults = await Promise.all(
        changedRows.map(row => validateRow(row))
      );

      if (!validationResults.every(result => result === true)) {
        console.log("Validation failed for some rows");
        return;
      }

      await submitChanges(onSaveChanges);
    } catch (error) {
      console.error("Save failed:", error);
    } finally {
      setIsValidating(false);
    }
  };

  const submitChanges = async onSaveChanges => {
    const apiChanges = prepareApiChanges(
      changes,
      targetAccountId,
      formatDateToDateTime
    );

    if (onSaveChanges) {
      await onSaveChanges(apiChanges);
      setOriginalData([...poData]);
      setChanges({ added: [], updated: [], deleted: [] });
      setHasChanges(false);
    }
  };

  return {
    poData,
    hasChanges,
    validationErrors,
    isValidating,
    rowsBeingValidated,
    rowHasErrors,
    hasValidationErrors,
    isNewRow,
    handleAddRow,
    handleRowUpdate,
    handleDeleteRow,
    handleCancel,
    handleSave
  };
};
