import {
  TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS,
  TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS
} from "@teamrota/rota-common";

export interface Venue {
  id: string;
  name: string;
  subvenues: Subvenue[];
}

export interface Subvenue {
  id: string;
  name: string;
}

export interface Owner {
  id: string;
  name: string;
}

export interface RoleRate {
  id: string;
  roleId: string;
  roleName: string;
}

export enum TimesheetStatus {
  "Approved" = "Approved",
  "Open" = "Open",
  "Locked" = "Locked"
}

export enum TimesheetInternalStatus {
  TO_DO = "TO_DO",
  IN_PROGRESS = "IN_PROGRESS",
  APPROVED = "APPROVED",
  LOCKED = "LOCKED"
}

export enum TimesheetMemberStatus {
  TBC = "TBC",
  STARTED = "STARTED",
  LATE = "LATE",
  FINISHED = "FINISHED",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  UPDATED = "UPDATED",
  REJECTED = "REJECTED"
}

export interface Member {
  id: string;
  firstName: string;
  lastName: string;
  avatarUrl: string;
  role: string;
  timeIn: string;
  timeOut: string;
  totalHours: number;
  status: string;
  totalPay: number;
  payRate: number;
  chargeRate: number;
  totalCharge: number;
  ratingOfMember: number;
}

export type FilterType = "selectedVenueIds" | "selectedRoleIds";

export type Filters = {
  [key in FilterType]: string[];
};

export type BaseOptionKey = keyof typeof TIMESHEET_BREAKDOWN_OPTIONAL_FIELDS;

export type SleepOptionKey = keyof typeof TIMESHEET_BREAKDOWN_OPTIONAL_SLEEP_TIME_FIELDS;

export type OptionKey = BaseOptionKey | SleepOptionKey;

type OptionTranslationsType = {
  [key in OptionKey]: string;
};

export const optionTranslations: OptionTranslationsType = {
  STATUS: "Status",
  VENUE: "Venue",
  SUBVENUE: "Sub-venue",
  BREAK: "Break",
  TOTAL_PAY: "Total Pay",
  PAY_RATE: "Pay Rate",
  TOTAL_CHARGE: "Total Charge",
  CHARGE_RATE: "Charge Rate",
  PURCHASE_ORDER_NUMBER: "PO no.",
  PLANNED_SLEEP_TIME: "Planned Sleep Time",
  PLANNED_SLEEP_TIME_TOTAL: "Planned Sleep Time Total",
  ACTUAL_SLEEP_TIME: "Actual Sleep Time",
  ACTUAL_SLEEP_TIME_TOTAL: "Actual Sleep Time Total",
  DISTURBED_SLEEP_TIME: "Disturbed Sleep Time",
  DISTURBED_SLEEP_TIME_TOTAL: "Disturbed Sleep Time Total"
};
