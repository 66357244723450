// POTable.jsx - Main component file
import React from "react";
import { RotaButton } from "@teamrota/rota-design";
import {
  TableWrapper,
  StyledTable,
  TableHead,
  TableHeader,
  ButtonContainer,
  ActionButton,
  TableContainer
} from "../po-numbers.styles";
import { COLUMN_WIDTHS } from "../consts";
import { getSubvenueOptions } from "../utils";
import { usePOTable } from "../hooks/use-po-table";
import POTableRow from "./po-table-row";
import asyncConfirm from "~/src/utils/async-confirm";

const POTable = ({
  initialPONumbers = [],
  venues,
  roleRates,
  onSaveChanges,
  targetAccountId
}) => {
  const {
    poData,
    hasChanges,
    isValidating,
    rowsBeingValidated,
    rowHasErrors,
    hasValidationErrors,
    handleAddRow,
    handleRowUpdate,
    handleDeleteRow,
    handleCancel,
    handleSave
  } = usePOTable(initialPONumbers, targetAccountId);

  // Prepare options for dropdowns
  const venueOptions =
    venues?.map(({ id, name }) => ({
      value: id,
      label: name
    })) || [];

  const roleRateOptions = roleRates.map(({ id, role }) => ({
    value: id,
    label: role.name
  }));

  // Handle row operations with parent notification
  const onRowUpdate = (id, field, value) => {
    handleRowUpdate(id, field, value);
  };

  const onRowDelete = async id => {
    if (
      await asyncConfirm("Do you want to delete this PO number?", {
        shouldHideSubText: true,
        shouldAddPadding: true,
        confirmButtonText: "Delete",
        falseButtonText: "Cancel"
      })
    ) {
      handleDeleteRow(id);
    }
  };

  const onAddRow = () => {
    handleAddRow();
  };

  const onSave = async () => {
    await handleSave(onSaveChanges);
  };

  return (
    <TableContainer>
      <TableWrapper>
        <StyledTable>
          <TableHead>
            <tr>
              <TableHeader width={COLUMN_WIDTHS.poNumber}>
                PO Number
              </TableHeader>
              <TableHeader width={COLUMN_WIDTHS.venue}>Venue</TableHeader>
              <TableHeader width={COLUMN_WIDTHS.subvenue}>
                Sub-venue
              </TableHeader>
              <TableHeader width={COLUMN_WIDTHS.role}>Role</TableHeader>
              <TableHeader width={COLUMN_WIDTHS.startDate}>
                Start Date
              </TableHeader>
              <TableHeader width={COLUMN_WIDTHS.endDate}>End Date</TableHeader>
              <TableHeader width={COLUMN_WIDTHS.status}>Active</TableHeader>
              <TableHeader width={COLUMN_WIDTHS.actions}></TableHeader>
            </tr>
          </TableHead>
          <tbody>
            {poData.map(row => (
              <POTableRow
                key={row.id}
                row={row}
                columnWidths={COLUMN_WIDTHS}
                venueOptions={venueOptions}
                subvenueOptions={getSubvenueOptions(row.venueId, venues)}
                roleRateOptions={roleRateOptions}
                isBeingValidated={!!rowsBeingValidated[row.id]}
                hasError={rowHasErrors(row.id)}
                onUpdate={onRowUpdate}
                onDelete={onRowDelete}
              />
            ))}
          </tbody>
        </StyledTable>
      </TableWrapper>
      <ButtonContainer>
        <RotaButton
          onClick={onAddRow}
          style={{ marginRight: "auto" }}
          variant="outlined"
          disabled={isValidating || Object.keys(rowsBeingValidated).length > 0}
        >
          Add {poData.length > 0 ? "another" : ""}
        </RotaButton>

        {hasChanges && (
          <>
            <ActionButton
              onClick={handleCancel}
              variant="outlined"
              disabled={
                isValidating || Object.keys(rowsBeingValidated).length > 0
              }
            >
              Cancel
            </ActionButton>
            <ActionButton
              onClick={onSave}
              disabled={
                isValidating ||
                Object.keys(rowsBeingValidated).length > 0 ||
                hasValidationErrors()
              }
            >
              {isValidating ? "Validating..." : "Save"}
            </ActionButton>
          </>
        )}
      </ButtonContainer>
    </TableContainer>
  );
};

export default POTable;
