import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";
import { useToast } from "@teamrota/rota-design";
import { useCallback } from "react";

const TIMESHEET_MEMBER_UPDATE = gql`
  mutation updateTimesheetMember(
    $id: ID!
    $ratingOfMember: Int
    $feedbackForMember: String
    $blockMember: Boolean
    $purchaseOrderNumber: String
    $breakMinutes: Int
    $isNoShow: Boolean
    $isTurnedAway: Boolean
    $isInputError: Boolean
    $isNoLongerRequired: Boolean
    $isSickness: Boolean
    $isAnnualLeave: Boolean
    $isUnknown: Boolean
    $isNone: Boolean
    $scheduledStartTime: String
    $scheduledEndTime: String
    $venueId: Int
    $subvenueId: Int
    $onCallOffSiteStart: String
    $onCallOffSiteEnd: String
    $onCallOnSiteStart: String
    $onCallOnSiteEnd: String
  ) {
    updateTimesheetMember(
      id: $id
      ratingOfMember: $ratingOfMember
      feedbackForMember: $feedbackForMember
      blockMember: $blockMember
      purchaseOrderNumber: $purchaseOrderNumber
      breakMinutes: $breakMinutes
      isNoShow: $isNoShow
      isTurnedAway: $isTurnedAway
      isInputError: $isInputError
      isNoLongerRequired: $isNoLongerRequired
      isSickness: $isSickness
      isAnnualLeave: $isAnnualLeave
      isUnknown: $isUnknown
      isNone: $isNone
      scheduledStartTime: $scheduledStartTime
      scheduledEndTime: $scheduledEndTime
      venueId: $venueId
      subvenueId: $subvenueId
      onCallOffSiteStart: $onCallOffSiteStart
      onCallOffSiteEnd: $onCallOffSiteEnd
      onCallOnSiteStart: $onCallOnSiteStart
      onCallOnSiteEnd: $onCallOnSiteEnd
    ) {
      id
      ratingOfMember
      feedbackForMember
      purchaseOrderNumber
      isNoShow
      isTurnedAway
      isInputError
      isNoLongerRequired
      isSickness
      isAnnualLeave
      isUnknown
      isNone
      scheduledStartTime
      scheduledEndTime
      venueId
      subvenueId
      onCallOffSiteStart
      onCallOffSiteEnd
      onCallOnSiteStart
      onCallOnSiteEnd
    }
  }
`;

interface Params {
  onCompleted?: () => void;
}

interface UpdateParams {
  id: string;
  ratingOfMember?: number;
  feedbackForMember?: string;
  blockMember?: boolean;
  purchaseOrderNumber?: string;
  breakMinutes?: string;
  isNoShow?: boolean;
  isTurnedAway?: boolean;
  isInputError?: boolean;
  isNoLongerRequired?: boolean;
  isSickness?: boolean;
  isAnnualLeave?: boolean;
  isUnknown?: boolean;
  isNone?: boolean;
  scheduledStartTime?: string;
  scheduledEndTime?: string;
  venueId?: number;
  subvenueId?: number | null;
  onCallOffSiteStart?: string;
  onCallOffSiteEnd?: string;
  onCallOnSiteStart?: string;
  onCallOnSiteEnd?: string;
}

export const useUpdateTimesheetMember = ({ onCompleted }: Params) => {
  const { showToast } = useToast();
  const [update, { loading }] = useMutation(TIMESHEET_MEMBER_UPDATE, {
    onError: error => {
      showToast(error.message, { severity: "error" });
      throw error;
    },
    onCompleted,
    refetchQueries: ["TimesheetsDetails", "TimesheetMembersSummary"]
  });

  const updateTimesheetMember = useCallback(
    async ({
      id,
      ratingOfMember,
      feedbackForMember,
      blockMember,
      breakMinutes,
      purchaseOrderNumber,
      isNoShow,
      isTurnedAway,
      isInputError,
      isNoLongerRequired,
      isSickness,
      isAnnualLeave,
      isUnknown,
      isNone,
      scheduledStartTime,
      scheduledEndTime,
      venueId,
      subvenueId,
      onCallOffSiteStart,
      onCallOffSiteEnd,
      onCallOnSiteStart,
      onCallOnSiteEnd
    }: UpdateParams) => {
      await update({
        variables: {
          id,
          ratingOfMember,
          feedbackForMember,
          blockMember,
          breakMinutes,
          purchaseOrderNumber,
          isNoShow,
          isTurnedAway,
          isInputError,
          isNoLongerRequired,
          isSickness,
          isAnnualLeave,
          isUnknown,
          isNone,
          scheduledStartTime,
          scheduledEndTime,
          venueId,
          subvenueId,
          onCallOffSiteStart,
          onCallOffSiteEnd,
          onCallOnSiteStart,
          onCallOnSiteEnd
        }
      });
    },
    [update]
  );

  return {
    updateTimesheetMember,
    loading
  };
};
