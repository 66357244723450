import { RotaButton } from "@teamrota/rota-design";
import styled from "styled-components";
import { COLORS, STYLE_CONSTS } from "~/src/styles/config.style";

export const Container = styled.div`
  position: relative;
  height: auto;
  margin-top: ${({ isMarginTop }) => (!isMarginTop ? "35px" : 0)};
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.text.primary};
  font-size: ${STYLE_CONSTS.FONT_MEDIUM};
  margin-top: ${({ marginTop }) => (marginTop ? STYLE_CONSTS.UNIT_2 : 0)};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? STYLE_CONSTS.UNIT_2 : 0};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? STYLE_CONSTS.SMALL_DIVIDER_BORDER : "none"};
  padding-bottom: ${({ borderBottom }) =>
    borderBottom ? STYLE_CONSTS.UNIT_3 : 0};
  cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  width: ${({ limitWidth }) => limitWidth && "max-content"};
`;

export const TableContainer = styled.div`
  width: 100%;
  margin: 1rem 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  max-height: 50vh;
`;
export const TableWrapper = styled.div`
  flex: 1 1 auto;
  overflow: visible;
  position: relative;
`;

export const StyledTable = styled.table`
  min-width: 100%;
  width: 100%;
  table-layout: fixed;
  background: white;
  border: 1px solid #e5e7eb;
  border-collapse: collapse;
  overflow: visible;
`;

export const TableHead = styled.thead`
  background: ${COLORS.MAIN_ORANGE};
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TableHeader = styled.th`
  padding: 0.75rem;
  text-align: left;
  font-weight: normal;
  color: ${COLORS.BLACK};
  width: ${props => props.width || "auto"};
  min-width: ${props => props.minWidth || "auto"};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const TableRow = styled.tr`
  ${props =>
    props.hasError &&
    `
    background-color: ${COLORS.BACKGROUND_RED}
  `}
`;

export const TableCell = styled.td`
  border-top: 1px solid #e5e7eb;
  width: ${props => props.width || "auto"};
  min-width: ${props => props.minWidth || "auto"};
  padding: 0 0.25rem;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
`;

export const ActionButton = styled(RotaButton)`
  margin-left: 0.75rem;
`;

export const DropdownWrapper = styled.div`
  position: relative;

  /* Style for better text expansion on hover */
  & .dropdown-menu > li {
    &:hover {
      overflow: visible !important;
      white-space: normal !important;
      z-index: 1000;
      position: relative;
      background-color: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
    }
  }
`;
