import React, { ComponentPropsWithoutRef, useState } from "react";

import moment from "moment-timezone";
import styled from "styled-components";

import {
  iconNew,
  RotaButtonIcon,
  Tr,
  Td,
  InnerCell,
  RotaCheckbox,
  RotaAvatar,
  RotaInput,
  RotaPlaceholder,
  useToast,
  RotaStatusLabel
} from "@teamrota/rota-design";

import { TimesheetMemberStatus } from "./types";
import {
  convertMinutesToTimeString,
  getMemberStatusType,
  timesheetStatusTranslations
} from "./utils";
import { TimesheetMemberWithStats } from "./use-timesheet-members-with-stats";
import { useUpdateTimesheetMember } from "./use-update-timesheet-member";

const { ChevronRight } = iconNew;

const MemberName = styled.div`
  font-weight: 500;
`;

const ShiftTime = styled.div``;

export const TimesheetRowLoader = () => {
  return (
    <Tr>
      <Td>
        <InnerCell>
          <RotaCheckbox isChecked={false} onClick={() => {}} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder circle size={48} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell style={{ textAlign: "left" }}>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaPlaceholder circle size={32} />
        </InnerCell>
      </Td>
      <Td>
        <InnerCell>
          <RotaButtonIcon onClick={() => {}}>
            <ChevronRight />
          </RotaButtonIcon>
        </InnerCell>
      </Td>
    </Tr>
  );
};

interface Props extends ComponentPropsWithoutRef<"div"> {
  timesheet: TimesheetMemberWithStats;
  logo?: string;
  currencyCode: string;
  isChecked: boolean;
  onToggle: () => void;
  setExpandedTimesheet: (timesheet: TimesheetMemberWithStats) => void;
  refetch: () => void;
}

export const TimesheetRow = ({
  timesheet,
  isChecked,
  onToggle,
  setExpandedTimesheet,
  refetch,
  ...props
}: Props) => {
  const { showToast } = useToast();
  const [purchaseOrderNumber, setPurchaseOrderNumber] = useState<string>(
    timesheet.purchaseOrderNumber || ""
  );

  const { updateTimesheetMember, loading } = useUpdateTimesheetMember({
    onCompleted: () => {
      showToast("Timesheet member was updated");
      refetch();
    }
  });

  const isSameDay = moment(timesheet.scheduledStartTime).isSame(
    timesheet.scheduledEndTime,
    "day"
  );

  const formattedStart = moment(timesheet.scheduledStartTime).format(
    "Do MMM YYYY"
  );
  const formattedEnd = moment(timesheet.scheduledEndTime).format("Do MMM YYYY");

  const isRejected =
    timesheet.status === TimesheetMemberStatus.APPROVED &&
    (timesheet.isNoShow || timesheet.isTurnedAway);

  const hasCheckInAndOutTimes = timesheet.checkInTime && timesheet.checkOutTime;

  const isOnCallTimesEnabledOnShift =
    timesheet.isOnCallTimesEnabledOnShift ||
    Boolean(timesheet.onCallOffSiteStart && timesheet.onCallOffSiteEnd);

  return (
    <>
      <Tr isActive={isChecked} style={{ zIndex: "unset" }}>
        <Td>
          <InnerCell>
            <RotaCheckbox
              isChecked={isChecked}
              isDisabled={
                timesheet.isPreview ||
                [
                  TimesheetMemberStatus.APPROVED,
                  TimesheetMemberStatus.REJECTED
                ].includes(timesheet.status)
              }
              onClick={onToggle}
            />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            <RotaAvatar src={timesheet.memberAvatar} />
          </InnerCell>
        </Td>
        <Td>
          <InnerCell style={{ textAlign: "left" }}>
            <MemberName>{timesheet.memberName}</MemberName>
            {timesheet.roleName}
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            {isSameDay ? (
              formattedStart
            ) : (
              <>
                {formattedStart} -
                <br /> {formattedEnd}
              </>
            )}
          </InnerCell>
        </Td>
        <Td>
          <InnerCell>
            {isOnCallTimesEnabledOnShift && <b>(On Call) </b>}
            {timesheet.venueName}
          </InnerCell>
          <ShiftTime>{`${moment(timesheet.scheduledStartTime).format(
            "HH:mm"
          )} - ${moment(timesheet.scheduledEndTime).format(
            "HH:mm"
          )}`}</ShiftTime>
        </Td>
        <Td>
          <Td>
            <ShiftTime>
              {hasCheckInAndOutTimes
                ? `${moment(timesheet.checkInTime).format("HH:mm")} - ${moment(
                    timesheet.checkOutTime
                  ).format("HH:mm")}`
                : "--:-- - --:--"}
            </ShiftTime>
          </Td>
        </Td>
        <Td>
          {isOnCallTimesEnabledOnShift ? (
            <InnerCell>
              <ShiftTime>
                (On Site){" "}
                {convertMinutesToTimeString(
                  timesheet.totalMinutes + timesheet.onCallOnSiteDuration
                )}
              </ShiftTime>
              <ShiftTime>
                (Off Site){" "}
                {convertMinutesToTimeString(
                  timesheet.onCallOffSiteDuration -
                    timesheet.onCallOnSiteDuration
                )}
              </ShiftTime>
            </InnerCell>
          ) : (
            <InnerCell>
              {convertMinutesToTimeString(timesheet.totalMinutes)}
            </InnerCell>
          )}
        </Td>
        <Td>
          <InnerCell>
            <RotaInput
              wrapperStyles={{ margin: 0 }}
              placeholder="1231231"
              isDisabled={
                loading ||
                timesheet.status === TimesheetMemberStatus.APPROVED ||
                timesheet.isPreview
              }
              value={purchaseOrderNumber}
              onChange={e => setPurchaseOrderNumber(e.target.value)}
              saveOnBlur={({ value }) => {
                updateTimesheetMember({
                  id: timesheet.id,
                  purchaseOrderNumber: value
                });
              }}
            />
          </InnerCell>
        </Td>
        <Td>
          <RotaStatusLabel
            type={getMemberStatusType(
              timesheet.status,
              timesheet.isTurnedAway,
              timesheet.isNoShow
            )}
            style={{ margin: "auto" }}
          >
            {isRejected
              ? "Rejected"
              : timesheetStatusTranslations[timesheet.status] || "Open"}
          </RotaStatusLabel>
        </Td>
        <Td>
          <InnerCell>
            <RotaButtonIcon onClick={() => setExpandedTimesheet(timesheet)}>
              <ChevronRight />
            </RotaButtonIcon>
          </InnerCell>
        </Td>
      </Tr>
    </>
  );
};
