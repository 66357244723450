import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import ReactTooltip from "react-tooltip";
import { GET_ROLE_RATE } from "./graphql";
import useAuth from "~/src/auth/hooks/use-auth";
import { Role } from "@teamrota/authlib";

import {
  StyledDivWrapper,
  StyledTable,
  StyledTH,
  StyledTD,
  StyledPBold,
  StyledPBoldOrange,
  StyledPOrange,
  StyledPOrangeMargin,
  Styled_P_small,
  StyledUncalculatedWrapper
} from "./styles";
import { useCurrency } from "~/src/containers/profile-loader";

const RateBreakdown = ({ roleRateId, handleShiftUpdate }) => {
  const { data, loading } = useQuery(GET_ROLE_RATE, {
    variables: {
      roleRateId
    },
    skip: !roleRateId,
    fetchPolicy: "network-only"
  });

  const { currencySymbol } = useCurrency();
  const isUncalculatedRate = data?.account?.roleRateById?.isUncalculatedRate;

  const [isShowRateBreakdown, setIsShowRateBreakdown] = useState(true);

  const toggleRateBreakdown = () => {
    setIsShowRateBreakdown(!isShowRateBreakdown);
  };

  const roleRate = data?.account?.roleRateById;

  useEffect(() => {
    if (roleRate) {
      handleShiftUpdate({
        isOnCallShiftsEnabledForRole: roleRate.isOnCallShiftsEnabled
      });
    }
  }, [roleRate, handleShiftUpdate]);

  if (!roleRateId) {
    return null;
  }

  if (isUncalculatedRate) {
    return (
      <StyledUncalculatedWrapper>
        <StyledPBoldOrange>This is an uncalculated role.</StyledPBoldOrange>
        <StyledPOrange>
          To view rates, go to "My Staff" - "Roles".
        </StyledPOrange>
      </StyledUncalculatedWrapper>
    );
  }

  const arrowUrl = isShowRateBreakdown
    ? "/images/upArrow.svg"
    : "/images/downArrow.svg";

  // Requester accounts cannot see the pay rate set by the Provider
  const { authed, roles } = useAuth();
  const isProviderAccount = authed && roles.includes(Role.PROVIDER);

  return (
    <>
      <StyledDivWrapper>
        <ReactTooltip />
        <img
          data-tip="Here is the base rate for this shift as well as any overtime/overstay rates. These can be edited in My Staff - Roles."
          src={"/images/info.svg"}
        />
        <StyledPOrangeMargin>Rate breakdown</StyledPOrangeMargin>
        <div onClick={toggleRateBreakdown}>
          <img style={{ marginLeft: 4 }} src={arrowUrl} />
        </div>
      </StyledDivWrapper>
      {isShowRateBreakdown && (
        <StyledTable>
          <thead>
            <tr>
              <StyledTH>
                <StyledPBold>Rate</StyledPBold>
              </StyledTH>
              {isProviderAccount && (
                <StyledTH>
                  <p>Pay</p>
                </StyledTH>
              )}
              <StyledTH>
                <p>Charge</p>
              </StyledTH>

              <StyledTH>
                <p>Applied</p>
              </StyledTH>
            </tr>
          </thead>
          <tbody>
            <tr>
              <StyledTD>
                <p>Default</p>
              </StyledTD>
              {isProviderAccount && (
                <StyledTD>
                  <StyledPBold>
                    {currencySymbol}{" "}
                    {loading ? `-` : (roleRate?.payRate / 100).toFixed(2)}
                  </StyledPBold>
                </StyledTD>
              )}
              <StyledTD>
                <StyledPBold>
                  {currencySymbol}{" "}
                  {loading ? `-` : (roleRate?.chargeRate / 100).toFixed(2)}
                </StyledPBold>
              </StyledTD>

              <StyledTD>
                {!roleRate?.isOvertimeRate && !roleRate?.isOverstayRate && (
                  <StyledPOrange>Always</StyledPOrange>
                )}
                {roleRate?.isOvertimeRate && (
                  <StyledPOrange>
                    Applied 0-{roleRate?.overtimeHoursLimit.toString()} hours /
                    week
                  </StyledPOrange>
                )}
                {roleRate?.isOverstayRate && (
                  <StyledPOrange>
                    Applied 0-{roleRate?.overstayHoursLimit.toString()} hours /
                    shift
                  </StyledPOrange>
                )}
              </StyledTD>
            </tr>
            <tr>
              <StyledTD>
                <p>Overtime</p>
              </StyledTD>
              {isProviderAccount && (
                <StyledTD>
                  {roleRate?.isOvertimeRate && (
                    <StyledPBold>
                      {`${currencySymbol} ${(
                        roleRate?.overtimePayRate / 100
                      ).toFixed(2)}`}
                    </StyledPBold>
                  )}
                  {!roleRate?.isOvertimeRate && (
                    <Styled_P_small>unavailable</Styled_P_small>
                  )}
                </StyledTD>
              )}
              <StyledTD>
                {roleRate?.isOvertimeRate && (
                  <StyledPBold>
                    {`${currencySymbol} ${(
                      roleRate?.overtimeChargeRate / 100
                    ).toFixed(2)}`}
                  </StyledPBold>
                )}
                {!roleRate?.isOvertimeRate && (
                  <Styled_P_small>unavailable</Styled_P_small>
                )}
              </StyledTD>

              <StyledTD>
                {roleRate?.isOvertimeRate && (
                  <StyledPOrange>
                    {`+${roleRate?.overtimeHoursLimit} hours / week`}
                  </StyledPOrange>
                )}
                {!roleRate?.isOvertimeRate && (
                  <Styled_P_small>unavailable</Styled_P_small>
                )}
              </StyledTD>
            </tr>
            <tr>
              <StyledTD>Overstay</StyledTD>
              {isProviderAccount && (
                <StyledTD>
                  {roleRate?.isOverstayRate && (
                    <StyledPBold>
                      {`${currencySymbol} ${(
                        roleRate?.overstayPayRate / 100
                      ).toFixed(2)}`}
                    </StyledPBold>
                  )}
                  {!roleRate?.isOverstayRate && (
                    <Styled_P_small>unavailable</Styled_P_small>
                  )}
                </StyledTD>
              )}
              <StyledTD>
                {roleRate?.isOverstayRate && (
                  <StyledPBold>
                    {`${currencySymbol} ${(
                      roleRate?.overstayChargeRate / 100
                    ).toFixed(2)}`}
                  </StyledPBold>
                )}
                {!roleRate?.isOverstayRate && (
                  <Styled_P_small>unavailable</Styled_P_small>
                )}
              </StyledTD>
              <StyledTD>
                {roleRate?.isOverstayRate && (
                  <StyledPOrange>
                    {`+${roleRate?.overstayHoursLimit} hours / shift`}
                  </StyledPOrange>
                )}
                {!roleRate?.isOverstayRate && (
                  <Styled_P_small>unavailable</Styled_P_small>
                )}
              </StyledTD>
            </tr>
          </tbody>
        </StyledTable>
      )}
    </>
  );
};

export default RateBreakdown;
