import { gql } from "@apollo/client";
import { useQuery } from "@apollo/client";
import { currencySupport } from "@teamrota/rota-common";

const TIMESHEET_DETAILS = gql`
  query TimesheetsDetails($id: ID!, $timesheetMemberRowId: ID) {
    account {
      id
      currencyRegion
      timesheetCostDetails(
        id: $id
        timesheetMemberRowId: $timesheetMemberRowId
      ) {
        totalVat
        totalWithVat
        totalBonusAmount
        totalOverHourAmount
        totalCharge
        totalPay
        overtimeChargeRate
        overstayChargeRate
        chargeRate
        payRate
        overHourRate
        totalOverHours
        totalRegularWorkedHours
      }
    }
  }
`;

export interface TimesheetCostDetails {
  totalVat: number;
  totalWithVat: number;
  totalBonusAmount: number;
  totalOverHourAmount: number;
  totalCharge: number;
  totalPay: number;
  overtimeChargeRate: number;
  overstayChargeRate: number;
  chargeRate: number;
  payRate: number;
  overHourRate: number;
  totalOverHours: number;
  totalRegularWorkedHours: number;
}

interface Params {
  id: string;
  timesheetMemberRowId: string;
}

export const useTimesheetBreakdownCostDetails = ({
  id,
  timesheetMemberRowId
}: Params) => {
  const { data, loading, error, refetch } = useQuery(TIMESHEET_DETAILS, {
    fetchPolicy: "cache-and-network",
    variables: { id, timesheetMemberRowId },
    onError: error => {
      throw error;
    }
  });

  const timesheetCostDetails: TimesheetCostDetails =
    data?.account?.timesheetCostDetails;

  const currencyCode =
    data?.account?.currencyRegion &&
    currencySupport[data?.account?.currencyRegion].code;

  return {
    timesheetCostDetails,
    currencyCode,
    loading,
    error,
    refetch
  };
};
