import React, { useState } from "react";

import { useStreamInfo } from "./use-stream-info";

import {
  ScrapeLink,
  StyledInfoBox,
  SourceRefs,
  ScrapeBox,
  ProviderLink,
  ImgOverflow
} from "./stream-info.styles";

export default function StreamInfo({ shift: { id, stream } }) {
  if (!stream) {
    return "";
  }

  const { sources } = useStreamInfo(id);

  const [selectedId, setSelectedId] = useState(null);
  const [isExpanded, setIsExpanded] = useState(null);

  const select = id => {
    setSelectedId(id === selectedId ? null : id);
    setIsExpanded(false);
  };

  const source = sources.get(selectedId);

  return (
    <StyledInfoBox>
      <SourceRefs>
        <div>ShiftStream Ref{stream.sources.length > 1 ? "s" : ""}: </div>
        {stream.sources
          .sort((a, b) => a.numberFilled - b.numberFilled)
          .map(({ id, numberRequested, numberFilled }) => (
            <ScrapeLink
              className={id === selectedId ? "selected" : ""}
              onClick={() => select(id)}
            >
              {id} ({numberFilled}/{numberRequested})
            </ScrapeLink>
          ))}
      </SourceRefs>

      {source && (
        <>
          <ScrapeBox>
            <ImgOverflow>
              <img
                src={source.screenUrl}
                onClick={() => setIsExpanded(!isExpanded)}
                className={isExpanded ? "expanded" : "'"}
              />
            </ImgOverflow>
          </ScrapeBox>

          <ProviderLink>
            View {source.id} on{" "}
            <a href={source?.url} target="_blank">
              {stream?.account?.sourceName} - {stream?.account?.name}
            </a>
          </ProviderLink>
        </>
      )}
    </StyledInfoBox>
  );
}
