import React from "react";
import { useGetPurchaseOrderNumbers } from "./graphql/get-po-numbers";
import { useGetVenues } from "./graphql/get-venues";
import { Container, Text } from "./po-numbers.styles";
import { RotaSearchInput, useToast } from "@teamrota/rota-design";
import Table from "./components/po-table";
import { useBulkMutatePurchaseOrderNumbers } from "./graphql/bulk-mutate-po-numbers";

const UserPONumbers = ({ targetAccountId, roleRates }) => {
  // Data fetching with loading/error states
  const {
    purchaseOrderNumbers,
    loading: poLoading
  } = useGetPurchaseOrderNumbers(targetAccountId);

  const { showToast } = useToast();

  const { bulkMutate } = useBulkMutatePurchaseOrderNumbers();

  const { venues, loading: venuesLoading } = useGetVenues(targetAccountId);

  const [searchTerm, setSearchTerm] = React.useState("");

  const isLoading = poLoading || venuesLoading;

  const handleSaveChanges = async changes => {
    try {
      const result = await bulkMutate({
        added: changes.added || [],
        updated: changes.updated || [],
        deleted: changes.deleted || []
      });

      if (result.success) {
        showToast("Changes saved successfully.", { severity: "success" });
      } else {
        showToast("Changes could not be saved.", { severity: "error" });
      }
    } catch (error) {
      showToast("Changes could not be saved.", { severity: "error" });
    }
  };

  return isLoading ? (
    ""
  ) : (
    <Container>
      <Text marginBottom>
        Assign PO numbers that will automatically apply to a venue or subvenue's
        timesheets.
      </Text>
      <RotaSearchInput
        onChange={value => setSearchTerm(value)}
        styles={{
          width: "100%"
        }}
        placeholder="Search venue or subvenue by name"
        value={searchTerm}
      />
      <Table
        initialPONumbers={purchaseOrderNumbers}
        venues={venues}
        roleRates={roleRates}
        searchTerm={searchTerm}
        targetAccountId={targetAccountId}
        onSaveChanges={handleSaveChanges}
      />
    </Container>
  );
};

export default UserPONumbers;
