import React from "react";
import styled from "styled-components";
import {
  THead,
  TrHead,
  Th as BaseTh,
  TBody,
  InnerCell,
  RotaCheckbox,
  RotaPlaceholder,
  RotaBox,
  RotaButtonIcon,
  iconNew,
  RotaNewModal,
  ModalHeader
} from "@teamrota/rota-design";

import { usePreviewTimesheetDetails } from "./use-preview-timesheet-details";
import { RotaTableScrollable } from "./style";
import TimesheetDetailsMemberRow, {
  TimesheetDetailsMemberRowLoader
} from "./timesheet-details-member-row";

import { TimesheetMemberWithStats } from "./use-timesheet-members-with-stats";
import { useProfile } from "../profile-loader";

const { ChevronLeft } = iconNew;

const Th = styled(BaseTh)`
  font-weight: 500;
  font-size: 16px;
  background-color: ${({ theme }) => theme.neutral.lighter};
`;

const Box = styled(RotaBox)`
  margin: 32px;
  width: auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  box-shadow: none;
`;

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 16px;
  align-items: center;
  padding: 16px 0px;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  max-height: calc(100% - 72px);
`;

const TimesheetDetailsLoader = () => {
  return (
    <Box>
      <HeaderWrapper>
        <RotaPlaceholder circle size={48} />
        <RotaPlaceholder size={24} maxWidth={180} />
        <RotaPlaceholder circle size={48} />
      </HeaderWrapper>
      <InnerWrapper>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox
                    isChecked={false}
                    isDisabled
                    onClick={() => {}}
                  />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", width: 266 }}>Name</Th>
              <Th>Rating</Th>
              <Th>In/Out</Th>
              <Th>Total hours</Th>
              <Th>Status</Th>
            </TrHead>
          </THead>
          <TBody>
            {Array.from({ length: 8 }).map((_, index) => (
              <TimesheetDetailsMemberRowLoader key={index} />
            ))}
          </TBody>
        </RotaTableScrollable>
      </InnerWrapper>
    </Box>
  );
};

export const PreviewTimesheetDetails = ({
  timesheet
}: {
  timesheet: TimesheetMemberWithStats;
}) => {
  const { timesheetMembers, loading, refetch } = usePreviewTimesheetDetails({
    id: timesheet.shiftId,
    memberId: timesheet.memberId
  });

  const { user } = useProfile() as any;

  const subvenues = user?.account?.venues?.find(
    (venue: any) => venue.id === timesheet.venueId
  )?.subvenues;

  const hasSubvenues = subvenues?.length > 0;

  if (loading && !timesheetMembers.length) return <TimesheetDetailsLoader />;
  if (!timesheetMembers) return null;

  return (
    <Box>
      <InnerWrapper>
        <RotaTableScrollable>
          <THead>
            <TrHead>
              <Th>
                <InnerCell>
                  <RotaCheckbox
                    isChecked={false}
                    isDisabled
                    onClick={() => {}}
                  />
                </InnerCell>
              </Th>
              <Th />
              <Th style={{ textAlign: "left", width: 266 }}>Name</Th>
              <Th style={{ minWidth: 200 }}>Cancellation</Th>
              <Th>In/Out</Th>
              <Th>Total hours</Th>
              {hasSubvenues && <Th>Sub-venue</Th>}
              <Th>Status</Th>
            </TrHead>
          </THead>
          <TBody>
            {timesheetMembers.map(timesheetMember => {
              return (
                <TimesheetDetailsMemberRow
                  timesheet={timesheet}
                  subvenues={subvenues}
                  key={timesheetMember.id}
                  timesheetMember={timesheetMember}
                  refetch={() => refetch()}
                  isPreview
                />
              );
            })}
          </TBody>
        </RotaTableScrollable>
      </InnerWrapper>
    </Box>
  );
};

const PreviewTimesheetDetailsModal = ({
  timesheet,
  onClose
}: {
  timesheet: TimesheetMemberWithStats;
  onClose: () => void;
}) => {
  return (
    <RotaNewModal
      onClose={onClose}
      header={
        <ModalHeader
          title={timesheet.shiftName || `Shift at ${timesheet.venueName}`}
          subtitle={
            "This is a preview. You will be able to approve timesheets shortly after the shift has ended."
          }
          startAction={
            <RotaButtonIcon onClick={onClose}>
              <ChevronLeft />
            </RotaButtonIcon>
          }
        />
      }
    >
      <PreviewTimesheetDetails timesheet={timesheet} />
    </RotaNewModal>
  );
};

export default PreviewTimesheetDetailsModal;
