import { gql, useQuery } from "@apollo/client";

const GET_SHIFT_STREAM_INFO = gql`
  query getShiftStreamInfo($shiftId: ID!) {
    account {
      id
      shift(id: $shiftId) {
        stream {
          sources {
            id
            numberRequested
            numberFilled
            url
            screenUrl
          }
        }
      }
    }
  }
`;

export const useStreamInfo = shiftId => {
  const { data, loading } = useQuery(GET_SHIFT_STREAM_INFO, {
    variables: { shiftId },
    skip: !shiftId,
    fetchPolicy: "network-only"
  });

  return {
    sources: new Map(
      data?.account?.shift?.stream?.sources?.map(source => [
        source.id,
        source
      ]) ?? []
    ),
    loading
  };
};
