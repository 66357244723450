import { gql, useMutation } from "@apollo/client";

export const VALIDATE_PURCHASE_ORDER = gql`
  mutation validatePurchaseOrderNumber(
    $id: ID
    $accountId: ID!
    $venueId: ID
    $subvenueId: ID
    $roleRateId: ID
    $startDate: Date!
    $endDate: Date!
  ) {
    validatePurchaseOrderNumber(
      id: $id
      accountId: $accountId
      venueId: $venueId
      subvenueId: $subvenueId
      roleRateId: $roleRateId
      startDate: $startDate
      endDate: $endDate
    ) {
      isValid
    }
  }
`;

export const useValidatePurchaseOrderNumber = () => {
  const [validatePO, { loading, error }] = useMutation(
    VALIDATE_PURCHASE_ORDER,
    {
      fetchPolicy: "network-only",
      onError: error => {
        throw error;
      }
    }
  );

  const validate = async ({
    id,
    accountId,
    venueId,
    subvenueId,
    roleRateId,
    purchaseOrderNumber,
    startDate,
    endDate
  }) => {
    const result = await validatePO({
      variables: {
        id,
        accountId,
        venueId,
        subvenueId,
        roleRateId,
        purchaseOrderNumber,
        startDate,
        endDate
      }
    });
    return result?.data?.validatePurchaseOrderNumber?.isValid ?? false;
  };

  return {
    validate,
    loading,
    error
  };
};
